import { useState, useEffect } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { autop } from '@wordpress/autop';

import { getLanguageId } from '@helpers/language';
import { StudioCheckup } from '@helpers/dataExists';
import { Button } from '@components';
import { checkEnabled } from '@components/OptionalFeature';
import styles from './ProductGroupTile.module.scss';
import ArrowRight from '@assets/icon/arrow-right.svg';

const ProductGroupTile = ({ data, sectionData, pageId }) => {
  const { ProductCode, Title, SubTitle, SmallDescription } = data;
  const [studioFetchedUrl, setStudioFetchedUrl] = useState('');
  const { locale } = useRouter();
  const languageId = getLanguageId(locale);

  // check Small Description is Enabled
  const checkSmallDesc = sectionData.find(
    (x) => x.DisplayOptionKey == 'SmallDescription',
  ).Enabled;
  // button text optional information
  const ButtonText = sectionData?.find(
    (x) => x?.DisplayOptionKey == 'ButtonText',
  )?.OptionInformation[locale];
  // check if Studio is enable on Hero
  const studioAvailable = checkEnabled(sectionData, 'StudioImage');

  useEffect(async () => {
    if (studioAvailable) {
      const fetchedStudioResult = await StudioCheckup(ProductCode, '640x640');
      setStudioFetchedUrl(fetchedStudioResult);
    }
  }, [ProductCode]);

  return (
    <Link href={`/products/${ProductCode}`} prefetch={false}>
      <div className={styles.Tile}>
        <div className={styles.ScaleHandler}>
          <div
            className={classNames(
              styles.Background,
              pageId === 1 ? styles.Home : null,
            )}
          >
            <img
              src={`/api/static/media/byfile/Products/${ProductCode}/BackgroundHero/1_N_N_N_I_640x360.jpg`}
              className={styles.BackgroundImage}
              alt={Title}
            />
          </div>
        </div>

        <div className={styles.Contents}>
          <div
            className={classNames(
              styles.Text,
              pageId === 1 ? styles.Dark : null,
            )}
          >
            <h3 className={styles.Title}>{Title}</h3>

            {pageId !== 1 && SubTitle !== '' && (
              <h6 className={styles.Title}>{SubTitle}</h6>
            )}
            {pageId !== 1 && checkSmallDesc && (
              <div
                className={styles.Description}
                dangerouslySetInnerHTML={{ __html: autop(SmallDescription) }}
              />
            )}
          </div>
          <div className={styles.Bottom}>
            {studioFetchedUrl && (
              <div className={styles.Model}>
                <img
                  src={studioFetchedUrl}
                  className={styles.ModelImage}
                  alt={Title}
                />
              </div>
            )}
            <Button
              href={`/products/${ProductCode}`}
              className={classNames(styles.Button, styles.ViewAll)}
              animated
            >
              {ButtonText}
              <ArrowRight />
            </Button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductGroupTile;
