import { Fragment, useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useSelector, useDispatch } from 'react-redux';

import { Accordion, Button, Container } from '@components';
import CompareContex from '@contexts/CompareContext';
import { compareProductSelector } from '@stores/compareProduct/selectors';
import { updateProductCompareCodeAction } from '@stores/compareProduct/actions';
import CloseIcon from '@assets/icon/close.svg';
import styles from './ComparePopup.module.scss';

const ComparePopup = ({ data, btnStatus }) => {
  const dispatch = useDispatch();

  const [productList, setProductList] = useState(data);
  const [disabled, setDisable] = useState(btnStatus);
  const checkedProducts = useSelector(compareProductSelector);

  const deleteHandler = (item) => {
    const CurrentProductCode = item.ProductCode;
    const DeleteItem = data.find(
      (elm) => elm.ProductCode === CurrentProductCode,
    );
    const NewData = data.filter(
      (elm) => elm.ProductCode !== DeleteItem.ProductCode,
    );
    setProductList(NewData);
    dispatch(updateProductCompareCodeAction(NewData.map((el) => el.ProductCode)));
    // setCheckedProducts(NewData.map((el) => el.ProductCode));
  };

  useEffect(() => {
    setProductList(data);

    //Compare button is disabled, if less than two products are available in popup
    {
      productList.length < 2 ? setDisable(true) : setDisable(false);
    }
  });

  return (
    <div className={styles.ComparePopup}>
      <section className={styles.Section}>
        <Container className={styles.Container}>
          <Accordion className={styles.Accordion} defaultActiveKey="expanded">
            <Accordion.Item compareBoxCheck title="Compare" eventKey="expanded">
              {productList.map((item, index) => (
                <div className={styles.Box} key={index}>
                  <button
                    className={styles.CloseButton}
                    onClick={() => deleteHandler(item)}
                  >
                    <CloseIcon className={styles.CloseIcon} />
                  </button>
                  <Fragment>
                    <div className={styles.ProductCode}>
                      <Image
                        className={styles.Image}
                        src={`/api/static/media/en/products/${item?.ProductCode}/studio/1/n/n/640x640/i`}
                        width="100"
                        alt= {`${item?.ProductText.Title}`}
                        height="100"
                      />
                      <h4>{item?.ProductCode}</h4>
                      <span className={styles.SpecText}>
                        <p>{item?.ProductSeriesDesc}</p>
                      </span>
                    </div>
                  </Fragment>
                </div>
              ))}
            </Accordion.Item>

            <Link
              href={`/products/comparison?codes=${checkedProducts.toString()}`}
              prefetch={false}
            >
              <Button className={styles.Button} animated disabled={disabled}>
                <a>Compare</a>
              </Button>
            </Link>
          </Accordion>
        </Container>
      </section>
    </div>
  );
};

export default ComparePopup;
