import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';
import classNames from 'classnames';
import { CheckboxInput } from '@components';
import { translatePrice } from '@/helpers/product';
import styles from './SimilarItem.module.scss';

const SimilarItem = ({
  className,
  title, //ok
  features,
  price,
  label,
  image,
  power,
  hours,
  dealer,
  usedID,
  category,
  comments,
}) => {
  const router = useRouter();
  const { locale } = router;

  return (
    <Link href={`/products/used/search/${category}/${usedID}`} prefetch={false}>
      <a className={classNames(styles.Tile, className)}>
        <div className={styles.ImageContainer}>
          {label && <span className={styles.Label}>{label}</span>}
          <Image
            className={styles.Image}
            src={image}
            layout="fill"
          />
        </div>
        <div className={styles.Content}>
          <div className={styles.Info}>
            <h3
              className={styles.Title}
              dangerouslySetInnerHTML={{
                __html: title
              }}
            />
            {features?.length > 0 && (
              <ul className={styles.QuickFeatures}>
                {features.map(({ SpecId, SpecDisplayText }) => (
                  <li className={styles.QuickFeature} key={SpecId}>
                    {SpecDisplayText}
                  </li>
                ))}
              </ul>
            )}
            {/* <span>{comments}</span> */}
            <div className={styles.Desc_container}>
              <span className={styles.Product_tile_desc}>{locale=='fr'? 'Concessionnaire':'Dealer'}: {dealer}</span>
              <br />
              <span className={styles.Product_tile_desc}>{locale=='fr'? 'CH':'HP'}: {power}</span>
              <br />
              <span className={styles.Product_tile_desc}>{locale=='fr'? 'Heures':'Hours'}: {hours}</span>
              <br />
            </div>
            <div className={styles.Price}>{translatePrice(price, locale)}</div>
          </div>
          {/* <CheckboxInput
            className={styles.Compare}
            onClick={(event) => event.preventDefault()}
            title="Compare"
          /> */}
        </div>
      </a>
    </Link>
  );
};

export default SimilarItem;
