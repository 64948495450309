import { ADD_PRODUCT_CODE, REMOVE_PRODUCT_CODE, UPDATE_PRODUCT_CODE, RESET_PRODUCT_CODE, ADD_PRODUCT_COMPARE, THREE_SIXTY_VIEWER,COMPARE_POPUP_SHOW } from '../types';

export const addProductCompareCodeAction = (code) => ({
  type: ADD_PRODUCT_CODE,
  payload: code
});

export const removeProductCompareCodeAction = (code) => ({
  type: REMOVE_PRODUCT_CODE,
  payload: code
});

export const updateProductCompareCodeAction = (code) => ({
  type: UPDATE_PRODUCT_CODE,
  payload: code
});

export const resetProductCompareCodeAction = () => ({
  type: RESET_PRODUCT_CODE
});

export const addProductCompareAction = (product) => ({
  type: ADD_PRODUCT_COMPARE,
  payload: product
});

export const ThreeSixtyViewer = (value) => ({
  type: THREE_SIXTY_VIEWER,
  payload: value
});
export const reqCompareBarAction = () => ({
  type: COMPARE_POPUP_SHOW,
});

export default {
  addProductCompareCodeAction,
  removeProductCompareCodeAction,
  updateProductCompareCodeAction,
  resetProductCompareCodeAction,
  addProductCompareAction,
  ThreeSixtyViewer,
  reqCompareBarAction
};
