/*
 * Products reducer
 */

import { INITIAL_STATE } from "./initialState";

import {
  FOOTER_GET_LOADED,
  FOOTER_GET_ERROR,
  FOOTER_MENU_GET_LOADED,
  FOOTER_MENU_GET_ERROR
} from "../types";

// Original State Reducer
const reducers = (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case FOOTER_GET_LOADED:
      return {
        ...state,
        items: action.payload,
        error: false,
      };

    case FOOTER_GET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case FOOTER_MENU_GET_LOADED:
      return {
        ...state,
        menuItems: action.payload,
        error: false,
      };

    case FOOTER_MENU_GET_ERROR:
      return {
        ...state,
        menuError: action.payload,
      };

    default:
      return state;
  }
};

export default reducers;
