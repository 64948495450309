function getKeyMetadata(metadata, container, optionKey) {
  if (!metadata || !container || !optionKey) {
    return null;
  }

  const keyMetadata = (metadata || []).find((displayOption) => displayOption?.DisplayOptionKey === optionKey);

  return keyMetadata;
}

// Determines whether or not container display is enabled or disabled
export function isFeatureEnabled(metadata, container = null, optionKey = 'DisplayComponent') {
  const keyMetadata = getKeyMetadata(metadata, container, optionKey);
  return keyMetadata?.Enabled || false;
}

// Returns display value for provided container/key/locale
export function getFeatureDisplayValue(metadata, container = null, optionKey = null, locale = 'en') {
  const keyMetadata = getKeyMetadata(metadata, container, optionKey);
  return (keyMetadata?.OptionInformation && keyMetadata.OptionInformation[locale]) || null;
}

//check Enabled of every item in DisplayOptions
export function checkEnabled(metadata, optionKey) {
  if (!metadata || !optionKey) {
    return null;
  }
  const keyMetadata = (metadata || []).find((displayOption) => displayOption?.DisplayOptionKey === optionKey);
  return keyMetadata?.Enabled || false;
}

//check the value in every item of DisplayOptions in a component
export function checkValue(metadata, optionKey, locale) {
  if (!metadata || !optionKey) {
    return null;
  }
  const keyMetadata = (metadata || []).find((displayOption) => displayOption?.DisplayOptionKey === optionKey);
  return (keyMetadata?.OptionInformation && keyMetadata.OptionInformation[locale]) || null;
}

export function categoryFilter(categorySequence,category){
  if(!categorySequence || !category){
    return null
  }
  let spiltArray = JSON.parse("[" + categorySequence + "]");
  let sortedArray = category?.sort((a,b)=>{
    return spiltArray?.indexOf(a.id) - spiltArray?.indexOf(b.id);
  });
  return sortedArray;
}