import classNames from 'classnames';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { MENU_RICH_STYLE } from '@/app-configuration';

import styles from './SubMenu.module.scss';

const SubMenuItem = ({ className, item, nested, ...props }) => {
  return (
    <li
      className={classNames(
        nested ? styles.NestedSubMenuItem : styles.SubMenuItem,
        className,
        {
          [styles.HasChildren]: item.children?.length > 0,
        },
      )}
    >
      {/* all children but adv */}
      {item.sequence !== 9999 &&
        (item.menuDetails?.url ? (
          <Link href={item.slug || item.menuDetails.url} passHref prefetch={false}>
            <motion.a
              className={classNames(
                item.children?.length > 0 ? styles.SubMenuTitle : styles.SubMenuLink,
              )}
              {...(item.children?.length === 0 && {
                variants: {
                  open: { x: 0, opacity: 1 },
                  collapsed: { x: '-100%', opacity: 0 },
                },
                transition: {
                  ease: 'easeInOut',
                  duration: 0.4,
                },
              })}
            >
              {item.menuDetails.image && (
                <img
                  src={`/api/static/media/byfile${item.menuDetails.image}`}
                  alt=""
                  className={styles.SubMenuLinkImg}
                />
              )}
              <span className={classNames(styles.SubMenuLinkText, {
                [styles.RichSubMenuLinkText]: MENU_RICH_STYLE,
              })}>
                {item.menuDetails.title}
                {item.menuDetails.subtitle && (
                  <span>{item.menuDetails.subtitle}</span>
                )}
              </span>
            </motion.a>
          </Link>
        ) : (
          <span
            className={classNames(
              item.children?.length > 0 ? styles.SubMenuTitle : styles.SubMenuLink,
            )}
          >
            {item.menuDetails.img && (
              <img src={item.menuDetails.img} alt="" className={styles.SubMenuLinkImg} />
            )}
            <span className={classNames(styles.NestedSubMenu, {
              [styles.RichSubMenuLinkText]: MENU_RICH_STYLE,
            })}>
              {item.menuDetails.title}
              {item.menuDetails.subtitle && (
                <span>{item.menuDetails.subtitle}</span>
              )}
            </span>
          </span>
        ))}
      {item.children?.length > 0 && (
        <ul className={classNames(styles.NestedSubMenu, {
          [styles.RichNestedSubMenu]: MENU_RICH_STYLE,
        })}>
          {item.children?.filter((x) => x.sequence!==9999)?.map((nestedItem) => (
            <SubMenuItem
              item={nestedItem}
              key={`child-page-nav-item-${nestedItem.id}`}
              nested
            />
          ))}
        </ul>
      )}
    </li>
  );
};

const SubMenu = ({ items, ...props }) => {
  const nestedItems = items.filter(
    (nestedItem) => nestedItem.children?.length > 0,
  );
  const DesignType = props.designType;
  const menuAdv = items
    .map((item) => item.children)
    .map((x) => x.find((y) => y.sequence == 9999 && y.target == 'Post'));

  return (
    <>
      <ul
        className={classNames(
          styles.SubMenu,
          {
            [styles.Expanded]: nestedItems.length > 0,
          },
          DesignType == 'TwoSubMenu'
            ? styles.TwoSubMenu
            : DesignType == 'NonSubMenuItems'
            ? styles.NonSubMenuItems
            : null,
        )}
      >
        {items?.length > 0 &&
          items?.map((item, index) => {
            return (
              <SubMenuItem item={item} key={`child-page-nav-item-${index}`} />
            );
          })}
      </ul>
    </>
  );
};

export default SubMenu;
