import axios from 'axios';
import * as AxiosLogger from 'axios-logger';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import { identity, isEmpty, omit } from 'lodash-es';

import { enableRaygunErrorReporting, logAndReportError } from '@/error-reporting';

import {
  WEBSITE_URL,
  NEXT_PUBLIC_LOG_HTTP_REQUESTS,
  NEXT_PUBLIC_RETRY_HTTP_REQUESTS,
  NEXT_PUBLIC_API_BASE_URL,
  NEXT_PUBLIC_CRM_BASE_URL,
  NEXT_PUBLIC_DEALER_BASE_URL,
  NEXT_PUBLIC_X_API_TOKEN,
  NEXT_PUBLIC_X_CRM_TOKEN,
  NEXT_PUBLIC_X_DEALER_TOKEN,
  RAYGUN_API_KEY,
  ENABLE_DEBUG_MODE
} from '@/app-configuration';

const X_API_TOKEN_HEADER = 'x-api-token';
const KEY_ID_HEADER = 'keyid';

function stringToBoolean(value) {
  switch (value.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;

    case 'false':
    case 'no':
    case '0':
    case null:
      return false;

    default:
      return Boolean(value);
  }
}

// Add logging feature to request and response
function enableLogger(instance) {
  if (stringToBoolean(ENABLE_DEBUG_MODE) === true) {
    AxiosLogger.setGlobalConfig({
      method: true,
      url: true,
      status: true,
      statusText: true,
      headers: true,
      params: true,
      data: false,
      dateFormat: 'dd-mm-yyyy h:MM:ss tt Z'
    });

    instance.interceptors.request.use(AxiosLogger.requestLogger, AxiosLogger.errorLogger);
    instance.interceptors.response.use(AxiosLogger.responseLogger, AxiosLogger.errorLogger);
  }
}

// Retry failed HTTP requests
function enableRetry(instance) {
  axiosRetry(instance, {
    retries: 2, // Retry HTTP calls twice
    retryDelay: axiosRetry.exponentialDelay, // Exponential back-off retry delay between requests
    retryCondition: (error) => {
      return (
        error?.response?.status !== 401 && // Do not retry requests with 401 response status
        isNetworkOrIdempotentRequestError(error)
      );
    }
  });
}

// Website client
const websiteClient = axios.create({
  baseURL: WEBSITE_URL
});

// Kubota API client
const apiClient = axios.create({
  baseURL: WEBSITE_URL + '/api/proxy/kubota-api'
});

// Kubota API client proxy
const apiClientProxy = axios.create({
  baseURL: NEXT_PUBLIC_API_BASE_URL,
  headers: {
    [X_API_TOKEN_HEADER]: NEXT_PUBLIC_X_API_TOKEN
  }
});

// CRM API client
const crmClient = axios.create({
  baseURL: WEBSITE_URL + '/api/proxy/kubota-crm'
});

// CRM API client proxy
const crmClientProxy = axios.create({
  baseURL: NEXT_PUBLIC_CRM_BASE_URL,
  headers: {
    [KEY_ID_HEADER]: NEXT_PUBLIC_X_CRM_TOKEN,
    [X_API_TOKEN_HEADER]: NEXT_PUBLIC_X_CRM_TOKEN
  }
});

// Dealer API client
const dealerClient = axios.create({
  baseURL: WEBSITE_URL + '/api/proxy/kubota-dealer'
});

// Dealer API client Proxy
const dealerClientProxy = axios.create({
  baseURL: NEXT_PUBLIC_DEALER_BASE_URL,
  headers: {
    [X_API_TOKEN_HEADER]: NEXT_PUBLIC_X_DEALER_TOKEN
  }
});

[websiteClient, apiClient, crmClient, dealerClient].forEach((httpClient) => {
  // Delete API Key headers to prevent leaks in browser
  httpClient.interceptors.request.use((config) => {
    config.headers = omit(config.headers, [X_API_TOKEN_HEADER, KEY_ID_HEADER]);
    return config;
  });
});

const HTTP_CLIENTS = [axios, websiteClient, apiClient, apiClientProxy, crmClient, dealerClientProxy, dealerClient];

// Enhance stack trace
HTTP_CLIENTS.forEach((httpClient) => axiosBetterStacktrace(httpClient));

if (!isEmpty(RAYGUN_API_KEY)) {
  // Enable HTTP requests error reporting
  HTTP_CLIENTS.forEach((httpClient) => {
    // Add axios interceptors for error reporting
    httpClient.interceptors.request.use(
      // This function is triggered before every HTTP request.
      identity,
      // This function is triggered when HTTP request call fails (prior to network call).
      // Log and report request error
      (error) => logAndReportError(error)
    );

    httpClient.interceptors.response.use(
      // Any status code that lie within the range of 2xx cause this function to trigger
      identity,
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Log and report response error
      (error) => logAndReportError(error)
    );
  });

  // Enable Raygun error reporting for uncaught JS errors
  enableRaygunErrorReporting();

  console.debug('Raygun Error Reporing Enabled');
}

// Enable HTTP requests logging
if (stringToBoolean(NEXT_PUBLIC_LOG_HTTP_REQUESTS) === true) {
  HTTP_CLIENTS.forEach(enableLogger);
  console.debug('Axios Logger Enabled');
}

// Enable HTTP requests retry
if (stringToBoolean(NEXT_PUBLIC_RETRY_HTTP_REQUESTS) === true) {
  console.debug('Axios Retry Enabled');
  HTTP_CLIENTS.forEach(enableRetry);
}

// Default enhanced Axios instance
export default axios;

export { websiteClient, apiClient, apiClientProxy, crmClient, crmClientProxy, dealerClient, dealerClientProxy };
