import chalk from 'chalk';
import { isFunction } from 'lodash';

import { ENABLE_DEBUG_MODE } from './app-configuration';

function createLogger(applyColor = null) {
  return (message) => {
    if (ENABLE_DEBUG_MODE === 'true') {
      const mappedMessage = isFunction(applyColor) ? applyColor(message) : message;
      console.log(mappedMessage);
    }
  };
}

const log = createLogger(); // Default logger
const logInfo = createLogger(chalk.blue); // Info logs text color
const logRedirect = createLogger(chalk.cyan); // Redirect logs text color
const logSuccess = createLogger(chalk.green); // Success logs text color
const logWarning = createLogger(chalk.yellow); // Warning logs text color
const logError = createLogger(chalk.bold.red); // Error logs text color

export { log, logInfo, logRedirect, logSuccess, logWarning, logError };
