import API from '@utils/api';
import { get, isEmpty } from 'lodash';

export default function LocationService() {
  async function getLocations(payload) {
    const response = await API.post('/api/locations', payload);

    const results = get(response, 'Response.Results.Data.Row', []);

    if (isEmpty(results)) {
      return [];
    }

    // Result is not always an array. A single object is returned if there's only one result matching search query.
    const locations = Array.isArray(results) ? results : Array.of(results);

    return locations.map((location) => ({
      distance: location._DISTANCE,
      address1: location.DEALER_ADDRESS_1,
      address2: location.DEALER_ADDRESS_2,
      city: location.DEALER_CITY,
      code: location.DEALER_CODE,
      name: location.DEALER_NAME,
      lat: location.LATITUDE,
      lng: location.LONGITUDE,
      postal: location.DEALER_POSTAL_CODE,
      province: location.DEALER_PROV,
      phone: location.TELEPHONE,
      handle: location.DEALER_NAME.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
    }));
  }

  return {
    getLocations
  };
}
