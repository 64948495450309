import { useMediaQuery } from 'react-responsive';

import {
  smBreakpoint,
  mdBreakpoint,
  lgBreakpoint,
  xlBreakpoint,
} from '@styles/styles.module.scss';

export const LargerDesktop = ({ children }) => {
  const isXL = useMediaQuery({ minWidth: xlBreakpoint });
  return isXL ? children : null;
};

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: lgBreakpoint });
  return isDesktop ? children : null;
};

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({
    minWidth: mdBreakpoint,
    maxWidth: parseInt(lgBreakpoint) - 1,
  });
  return isTablet ? children : null;
};

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: parseInt(mdBreakpoint) - 1 });
  return isMobile ? children : null;
};

export const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: mdBreakpoint });
  return isNotMobile ? children : null;
};

export const isDesktop = () => {
  return useMediaQuery({ minWidth: lgBreakpoint });
};

export const isMobile = () => {
  return useMediaQuery({ maxWidth: parseInt(mdBreakpoint) - 1 });
};
