import { take, call, put, all, select } from 'redux-saga/effects';

import LocationService from '@services/locationService';

import {
  APP_STARTLOADING,
  APP_STOPLOADING,
  LOCATIONS_GET_REQUEST,
  LOCATIONS_GET_LOADED,
  LOCATIONS_GET_ERROR,
  LOCATIONS_RESET_REQUEST,
  LOCATIONS_RESET_LOADED,
  MY_DEALER_SAVE_REQUEST,
  MY_DEALER_SAVE_LOADED
} from '../types';

function* getLocationsSaga(payload) {
  try {
    const locationService = LocationService();
    const locations = yield call(locationService.getLocations, payload);
    yield put({ type: LOCATIONS_GET_LOADED, payload: locations });
  } catch (error) {
    const errors = error.payload || error;
    yield put({ type: LOCATIONS_GET_ERROR, payload: errors });
  }
}

// RESET LOCATIONS OBJECT IN STATE
function* resetLocationsSaga() {
  try {
    yield put({ type: LOCATIONS_GET_LOADED });
  } catch (error) {
    const errors = error.payload || error;
    yield put({ type: LOCATIONS_GET_ERROR, payload: errors });
  }
}

/*
 * Save handle of selected dealer
 */
function* saveMyRetailerSaga(payload) {
  try {
    yield put({ type: MY_DEALER_SAVE_LOADED, payload: payload.location });
  } catch (error) {
    const errors = error.payload || error;
    yield put({ type: LOCATIONS_GET_ERROR, payload: errors });
  }
}

/*
 * Startup flow to allow concurrent actions to be dispatched
 */
function* startupFlow() {
  while (true) {
    // watching for authentication actions
    const action = yield take([LOCATIONS_GET_REQUEST, LOCATIONS_RESET_REQUEST, MY_DEALER_SAVE_REQUEST]);

    yield put({ type: APP_STARTLOADING });

    if (action.type === LOCATIONS_GET_REQUEST) {
      yield call(getLocationsSaga, action.payload);
    }

    if (action.type === LOCATIONS_RESET_REQUEST) {
      yield call(resetLocationsSaga, action.payload);
    }

    if (action.type === MY_DEALER_SAVE_REQUEST) {
      yield call(saveMyRetailerSaga, action.payload);
    }

    yield put({ type: APP_STOPLOADING });

    yield action;
  }
}

export default function* LocationsSagas() {
  yield all([startupFlow()]);
}
