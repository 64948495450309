import classNames from 'classnames';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { translatePrice } from '@helpers/product';
// import { CheckboxInput } from '@components';

const DynamicImage = dynamic(() => import('@components/DynamicImage'), { ssr: false });

import styles from './ProductTile.module.scss';

const handle = (category) => {
  return category
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

const ProductTile = ({
  className,
  title,
  features,
  price,
  label,
  usedBadge,
  image,
  productCode,
  productId,
  usedID,
  itemTitle,
  category,
  comments,
  power,
  hours,
  dealer
}) => {
  const router = useRouter();
  const { locale } = router;

  return (
    <Link href={`/products/used/search/${handle(category)}/${usedID}`} prefetch={false}>
      <a className={classNames(styles.Tile, className)}>
        <div className={styles.ImageContainer}>
          {label && <span className={styles.Label}>{label}</span>}
          {usedBadge && (
            <DynamicImage
              imgSrc={`/api/static/media/byfile/UsedMachinery/CertifiedUsed/1_N_N_N_I_360x538.png`}
              sizes={'100x149'}
              alt="CertifiedUsed"
              className={styles.Badge}
            />
          )}
          <img className={styles.Image} src={image} layout="fill" alt={itemTitle} />
        </div>
        <div className={styles.Content}>
          <div className={styles.Info}>
            <h3
              className={styles.Title}
              dangerouslySetInnerHTML={{
                __html: title
              }}
            />
            {features?.length > 0 && (
              <ul className={styles.QuickFeatures}>
                {features.map(({ SpecId, SpecDisplayText }) => (
                  <li className={styles.QuickFeature} key={SpecId}>
                    {SpecDisplayText}
                  </li>
                ))}
              </ul>
            )}
            {/* <span>{comments}</span> */}
            <div className={styles.Desc_container}>
              <span className={styles.Product_tile_desc}>{locale=='fr'? 'Concessionnaire':'Dealer'}: {dealer}</span>
              <br />
              <span className={styles.Product_tile_desc}>{locale=='fr'? 'CH':'HP'}: {power}</span>
              <br />
              <span className={styles.Product_tile_desc}>{locale=='fr'? 'Heures':'Hours'}: {hours}</span>
              <br />
            </div>
            <div className={styles.Price}>{translatePrice(price, locale)}</div>
          </div>

          {/* <CheckboxInput
            className={styles.Compare}
            onClick={(event) => event.preventDefault()}
            title="Compare"
          /> */}
        </div>
      </a>
    </Link>
  );
};

export default ProductTile;
