import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import styles from './StepProgress.module.scss';

const StepProgress = ({ steps = 3, focus = 1, texts = [], hidden = 0, handleStep = () => {}, }) => (
  <ul className={styles.StepBar} id="stepbar">
    {steps > 0 ? (
      <>
        {Array.from(Array(steps), (_, index) => (
          <li
            key={index}
            className={classnames(styles.Step, {
              [styles.Active]: index + 1 <= focus,
            })}
            style={{display: (hidden && hidden === index + 1) ? 'none' : 'block'}}
            onClick={() => handleStep(index + 1)}
          >
            {texts[index] && (
              <p className={styles.Text}>
                {texts[index]}
              </p>
            )}
            <div className={styles.Background} />
          </li>
        ))}
      </>
    ) : null}
  </ul>
);

export default StepProgress;
