import { take, call, put, all, select } from 'redux-saga/effects';

import DealerService from '@services/dealerService';

import {
  APP_STARTLOADING,
  APP_STOPLOADING,
  DEALER_REQUEST,
  DEALER_LOADED,
  DEALER_ERROR,
} from "../types";


/*
 * Get Product Filters
 */
function* getDealerInfoSaga(payload) {
  try {
    const state = yield select((state) => state);
    const dealerService = DealerService(state);
    const list = yield call(dealerService.getDealerInfo, payload);
    yield put({ type: DEALER_LOADED, payload: list[0] });

  } catch (error) {
    yield put({ type: DEALER_ERROR, payload: error });
  }
}


/*
 * Startup flow to allow concurrent actions to be dispatched
 */
function* startupFlow() {
  while (true) {
    // watching for authentication actions
    const action = yield take([
      DEALER_REQUEST,
    ]);

    yield put({ type: APP_STARTLOADING })

    if (action.type === DEALER_REQUEST) {
      yield call(getDealerInfoSaga, action.payload);
    }

    yield put({ type: APP_STOPLOADING });

    yield action;
  }
}

export default function* DealerSagas() {
  yield all([startupFlow()]);
}
