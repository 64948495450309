import classNames from 'classnames';
import { motion } from 'framer-motion';

import styles from './Dimmer.module.scss';

// TODO: Add different dimmer variants based on different heroes in PixelCarve proof of concept.

const Dimmer = ({ className, ...props }) => (
  <motion.div className={classNames(styles.Overlay, className)} {...props} />
);

export default Dimmer;
