import classNames from 'classnames';
import Link from 'next/link';
import { Background, Container } from '@components';

import styles from './FeaturedItem.module.scss';

const FeaturedItem = ({
  className,
  background,
  title,
  children,
  hasShadow,
  shadowType,
  href,
  ...props
}) => {

  return (
    <Link href={href ? href : ''} passHref prefetch={false}>
      <div
        className={classNames(
          styles.FeaturedItem,
          styles.ScaleEffect,
          className,
        )}
        {...props}
      >
        {background && (
          <Background
            {...background}
            hasShadow={hasShadow}
            shadowType={shadowType}
          />
        )}
        <Container className={styles.Container}>
          <div className={styles.Content}>
            <h2 className={styles.Title}>{title}</h2>
            <div className={styles.Text}>{children}</div>
          </div>
        </Container>
      </div>
    </Link>
  );
};

export default FeaturedItem;
