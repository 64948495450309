/**
* Combine all reducers in this file and export the combined reducers.
**/

import { combineReducers } from 'redux'

// Import reducers here
import app from './app/reducers'
import products from './products/reducers'
import locations from './locations/reducers'
import menu from './menu/reducers'
import used from './used/reducers'
import dealer from './dealer/reducers'
import compareProduct from './compareProduct/reducers'
import footer from './footer/reducers'
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer() {

  const rootReducer = combineReducers({
    app,
    products,
    locations,
    menu,
    used,
    dealer,
    compareProduct,
    footer,
  })

  return rootReducer
}
