import { take, call, put, all, select } from 'redux-saga/effects';

import ProductService from '@services/productService';

import {
  APP_STARTLOADING,
  APP_STOPLOADING,
  USED_COLLECTION_REQUEST,
  USED_COLLECTION_LOADED,
  USED_COLLECTION_ERROR
} from '../types';

/*
 * Get Product Filters
 */
function* getUsedProductsSaga(payload) {
  try {
    const state = yield select((state) => state);
    const productService = ProductService(state);
    const list = yield call(productService.useUsedProducts, payload);
    yield put({ type: USED_COLLECTION_LOADED, payload: list });
  } catch (error) {
    yield put({ type: USED_COLLECTION_ERROR, payload: error });
  }
}

/*
 * Startup flow to allow concurrent actions to be dispatched
 */
function* startupFlow() {
  while (true) {
    // watching for authentication actions
    const action = yield take([USED_COLLECTION_REQUEST]);

    yield put({ type: APP_STARTLOADING });

    if (action.type === USED_COLLECTION_REQUEST) {
      yield call(getUsedProductsSaga, action.payload);
    }

    yield put({ type: APP_STOPLOADING });

    yield action;
  }
}

export default function* UsedProductSagas() {
  yield all([startupFlow()]);
}
