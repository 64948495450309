import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { isMobile } from '@helpers/breakpoints';
import SearchIcon from '@assets/icon/search.svg';
import CloseIcon from '@assets/icon/close.svg';
import styles from './Search.module.scss';

const Search = ({ id }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { locale } = useRouter();
  const isMobileOpen = isMobile();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `/scripts/addSearch/ui-${locale}.js`;
    script.async = true;

    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, []);

  return (
    <>
      <div
        className={classNames(styles.SearchWrapper, {
          [styles.Expanded]: isMobileOpen || isOpened,
        })}
      >
        {(isMobileOpen || isOpened) && (
          <span className={styles.SearchInputPrepend}>
            <SearchIcon />
          </span>
        )}
        <div className={classNames('searchInput searchInputNav', styles.SearchInput)} id="searchInput">
          <div className="adds-components">
            <div className="adds-top-wrapper">
              <div id="searchfield-container"></div>
              <div id="autocomplete-container"></div>
             </div>
          </div>
        </div>
        <span
          className={styles.SearchToggle}
          onClick={() => {
            const element = document.querySelector(`#searchInput`).querySelector('input[type=search]');
            if (element) {
              setTimeout(() => element.focus(), 100);
            }
            setIsOpened(!isOpened);
          }}
        >
          {isOpened ? <CloseIcon /> : <SearchIcon />}
        </span>
      </div>
    </>
  );
};

export default Search;
