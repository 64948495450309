const path = require('path');
const HttpBackend = require('i18next-http-backend/cjs');
const ChainedBackend = require('i18next-chained-backend');
const LocalStorageBackend = require('i18next-localstorage-backend');

const isBrowser = typeof window !== 'undefined';

module.exports = {
  debug: false,
  serializeConfig: true,
  reloadOnPrerender: true,
  i18n: {
    locales: ['en', 'fr'],
    defaultLocale: 'en'
  },
  localePath: path.resolve('./public/locales'),
  use: isBrowser ? [ChainedBackend] : [],
  backend: {
    backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
    backendOptions: [
      { expirationTime: 60 * 60 * 1000 }, // 1 hour
      {}
    ]
  }
};
