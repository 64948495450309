/*
 * Products reducer
 */
import { INITIAL_STATE } from "./initialState";

import {
  USED_COLLECTION_LOADED,
  USED_COLLECTION_ERROR,
} from "../types";

// Original State Reducer
const reducers = (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case  USED_COLLECTION_LOADED:
      return {
        ...state,
        products: action.payload,
        error: false,
      };

    case USED_COLLECTION_ERROR:
      return {
        ...state,
        products: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducers;
