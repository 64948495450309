// Desctrucuring won't work here because Next.js will replace environment varibales values at build time
const BUILD_TARGET = process.env.BUILD_TARGET;
const WEBSITE_URL = process.env.WEBSITE_URL;
const NEXT_PUBLIC_LOG_HTTP_REQUESTS = process.env.NEXT_PUBLIC_LOG_HTTP_REQUESTS;
const NEXT_PUBLIC_RETRY_HTTP_REQUESTS = process.env.NEXT_PUBLIC_RETRY_HTTP_REQUESTS;
const NEXT_PUBLIC_API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
const NEXT_PUBLIC_CRM_BASE_URL = process.env.NEXT_PUBLIC_CRM_BASE_URL;
const NEXT_PUBLIC_DEALER_BASE_URL = process.env.NEXT_PUBLIC_DEALER_BASE_URL;
const NEXT_PUBLIC_USED_EQUIPMENT_IMAGE_URL = process.env.NEXT_PUBLIC_USED_EQUIPMENT_IMAGE_URL;
const NEXT_PUBLIC_X_API_TOKEN = process.env.NEXT_PUBLIC_X_API_TOKEN;
const NEXT_PUBLIC_X_CRM_TOKEN = process.env.NEXT_PUBLIC_X_CRM_TOKEN;
const NEXT_PUBLIC_X_DEALER_TOKEN = process.env.NEXT_PUBLIC_X_DEALER_TOKEN;
const RAYGUN_API_KEY = process.env.RAYGUN_API_KEY || "";
const ENABLE_DEBUG_MODE = process.env.ENABLE_DEBUG_MODE || false;
const MENU_RICH_STYLE = process.env.MENU_RICH_STYLE === "true" || false;
const HOSTNAME = typeof window !== "undefined" && window.location.hostname ? window.location.hostname : '';
const NEXT_PUBLIC_GOOGLE_MAPS_KEY = ["127.0.0.1", "localhost"].includes(HOSTNAME.toLowerCase()) ? process.env.NEXT_PUBLIC_STAGING_GOOGLE_MAPS_KEY : process.env.NEXT_PUBLIC_PROD_GOOGLE_MAPS_KEY;

export {
  BUILD_TARGET,
  WEBSITE_URL,
  NEXT_PUBLIC_LOG_HTTP_REQUESTS,
  NEXT_PUBLIC_RETRY_HTTP_REQUESTS,
  NEXT_PUBLIC_API_BASE_URL,
  NEXT_PUBLIC_CRM_BASE_URL,
  NEXT_PUBLIC_DEALER_BASE_URL,
  NEXT_PUBLIC_USED_EQUIPMENT_IMAGE_URL,
  NEXT_PUBLIC_X_API_TOKEN,
  NEXT_PUBLIC_X_DEALER_TOKEN,
  NEXT_PUBLIC_X_CRM_TOKEN,
  RAYGUN_API_KEY,
  ENABLE_DEBUG_MODE,
  MENU_RICH_STYLE,
  NEXT_PUBLIC_GOOGLE_MAPS_KEY,
};
