/*
 * Products reducer
 */

import { INITIAL_STATE } from "./initialState";

import {
  MENU_GET_LOADED,
  MENU_GET_ERROR,
} from "../types";

// Original State Reducer
const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MENU_GET_LOADED:
      return {
        ...state,
        items: action.payload,
        error: false,
      };

    case MENU_GET_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducers;
