import { isFeatureEnabled, getFeatureDisplayValue, checkEnabled } from '@components/OptionalFeature';

export function buildArticleStructuredData(data) {
  return {
    '@type': 'Article',
    'publisher': {
      '@type': data?.DisplayPage?.Description,
      'name': data?.PostedBy
    },
    'datePublished': data?.PostedDate,
    'dateCreated': data?.DisplayPage?.CreateDate,
    'dateModified': data?.DisplayPage?.LastModifiedDate,
    'description': data?.description,
    'articleBody': data?.articleBody
  };
}
