// App Startup action types
export const APP_STARTUP_REQUEST = 'app/index/APP_STARTUP_REQUEST';
export const APP_STARTUP_LOADED = 'app/index/LOADED';
export const APP_STARTUP_ERROR = 'app/startup/APP_STARTUP_ERROR';
export const APP_STARTLOADING = 'app/loader/STARTLOADING';
export const APP_STOPLOADING = 'app/loader/STOPLOADING';
export const APP_LANG_REQUEST = 'app/lang/APP_LANG_REQUEST';
export const APP_LANG_LOADED = 'app/loaded/APP_LANG_LOADED';

// Product GET action types
export const PRODUCTS_GET_REQUEST = 'products/get/PRODUCTS_GET_REQUEST';
export const PRODUCTS_GET_LOADED = 'products/loaded/PRODUCTS_GET_LOADED';
export const PRODUCTS_GET_ERROR = 'products/error/PRODUCTS_GET_ERROR';

// Product GET action types
export const PRODUCT_GET_REQUEST = 'product/get/PRODUCT_GET_REQUEST';
export const PRODUCT_GET_LOADED = 'product/loaded/PRODUCT_GET_LOADED';
export const PRODUCT_GET_ERROR = 'product/error/PRODUCT_GET_ERROR';

// Locations GET action types
export const LOCATIONS_GET_REQUEST = 'locations/get/LOCATIONS_GET_REQUEST';
export const LOCATIONS_GET_LOADED = 'locations/loaded/LOCATIONS_GET_LOADED';
export const LOCATIONS_GET_ERROR = 'locations/error/LOCATIONS_GET_ERROR';
export const LOCATIONS_RESET_REQUEST = 'locations/resetrequest/LOCATIONS_RESET_REQUEST';
export const LOCATIONS_RESET_LOADED = 'locations/resetloaded/LOCATIONS_RESET_LOADED';

// Save as my dealer
export const MY_DEALER_SAVE_REQUEST = 'mydealer/save/MY_DEALER_SAVE_REQUEST';
export const MY_DEALER_SAVE_LOADED = 'mydealer/loaded/MY_DEALER_SAVE_LOADED';
export const MY_DEALER_REMOVE_REQUEST = 'mydealer/remove/MY_DEALER_REMOVE_REQUEST';
export const MY_DEALER_REMOVE_LOADED = 'mydealer/loadedremove/MY_DEALER_REMOVE_LOADED';
export const MY_DEALER_ERROR = 'mydealer/error/MY_DEALER_ERROR';

// Page Level Actions
export const PAGE_DATA_REQUEST = 'page/request/PAGE_DATA_REQUEST';
export const PAGE_DATA_LOADED = 'page/loaded/PAGE_DATA_LOADED';
export const PAGE_DATA_ERROR = 'page/error/PAGE_DATA_ERROR';

// Main Menu Actions
export const MENU_GET_REQUEST = 'menu/request/MENU_GET_REQUEST';
export const MENU_GET_LOADED = 'menu/loaded/MENU_GET_LOADED';
export const MENU_GET_ERROR = 'menu/error/MENU_GET_ERROR';

// Used Product Fetching
export const USED_COLLECTION_REQUEST = 'used/get/USED_COLLECTION_REQUEST';
export const USED_COLLECTION_LOADED = 'used/loaded/USED_COLLECTION_LOADED';
export const USED_COLLECTION_ERROR = 'used/error/USED_COLLECTION_ERROR';

// Dealer Fetching
export const DEALER_REQUEST = 'dealer/get/DEALER_REQUEST';
export const DEALER_LOADED = 'dealer/loaded/DEALER_LOADED';
export const DEALER_ERROR = 'dealer/error/DEALER_ERROR';

// Compare Product
export const ADD_PRODUCT_CODE = 'compareProduct/loaded/ADD_PRODUCT_CODE';
export const REMOVE_PRODUCT_CODE = 'compareProduct/remove/REMOVE_PRODUCT_CODE';
export const UPDATE_PRODUCT_CODE = 'compareProduct/remove/UPDATE_PRODUCT_CODE';
export const RESET_PRODUCT_CODE = 'compareProduct/reset/RESET_PRODUCT_CODE';
export const ADD_PRODUCT_COMPARE = 'compareProduct/loaded/ADD_PRODUCT_COMPARE';
export const THREE_SIXTY_VIEWER = 'compareProduct/loaded/THREE_SIXTY_VIEWER';
export const COMPARE_POPUP_SHOW = 'compareProduct/IS_COMPARE_SHOW';


// Footer Actions
export const FOOTER_GET_REQUEST = 'menu/loaded/FOOTER_GET_REQUEST';
export const FOOTER_GET_LOADED = 'menu/loaded/FOOTER_GET_LOADED';
export const FOOTER_GET_ERROR = 'menu/error/FOOTER_GET_ERROR';

// Footer Menu Actions
export const FOOTER_MENU_GET_REQUEST = 'menu/loaded/FOOTER_MENU_GET_REQUEST';
export const FOOTER_MENU_GET_LOADED = 'menu/loaded/FOOTER_MENU_GET_LOADED';
export const FOOTER_MENU_GET_ERROR = 'menu/error/FOOTER_MENU_GET_ERROR';
