export const getDealerTiming = (array) => {
  if (array) {
    let isSameTime = false;
    if (array.every(e => e.department == 'All departments')) {
      return { isSameTime: true, timing: array };
    }
    else {
      array = array.filter(f => f.department != 'All departments');
      let group = groupArrayByKey(array, 'day');
      let isEqualLength = group.every(e => e.item.length == group[0].item.length);
      if (isEqualLength) {
        isSameTime = group.every(e => e.item.every(ee => ee.open == e.firstObj.open && ee.close == e.firstObj.close));
      }
      else {
        isSameTime = false;
      }

      if (isSameTime) {
        let groupDepartment = groupArrayByKey(array, 'department');
        let filterDays = groupDepartment.map(m => ({ ...m, item: m.item.filter((item, pos, self) => self.map(m => m.day).indexOf(item.day) == pos) }));
        return { isSameTime, timing: filterDays[0].item };
      }
      else {
        let groupDepartment = groupArrayByKey(array, 'department');
        let filterDays = groupDepartment.map(m => ({ ...m, item: m.item.filter((item, pos, self) => self.map(m => m.day).indexOf(item.day) == pos) }));
        return { isSameTime, timing: filterDays };
        // setTiming({ isSameTime, timing: filterDays });
      }
    }
  }
}
const groupArrayByKey = (array, groupKey) => {
  let object = array?.reduce((r, a) => {
    r[a[groupKey]] = r[a[groupKey]] || [];
    r[a[groupKey]].push(a);
    return r;
  }, Object.create(null));
  let arr = Object.keys(object).map((k) => {
    return { [groupKey]: k, item: object[k], firstObj: object[k][0] };
  });
  return arr;
}
