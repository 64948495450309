/*
 * Products reducer
 */

import { INITIAL_STATE } from './initialState';

import {
  PRODUCTS_GET_LOADED,
  PRODUCTS_GET_ERROR,
  PRODUCT_GET_LOADED,
  PRODUCT_GET_ERROR,
} from '../types';

// Original State Reducer
const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PRODUCTS_GET_LOADED:
      return {
        ...state,
        products: action.payload,
        error: false,
      };

    case PRODUCTS_GET_ERROR:
      return {
        ...state,
        products: false,
        error: action.payload,
      };

    case PRODUCT_GET_LOADED:
      return {
        ...state,
        product: action.payload,
        error: false,
      };

    case PRODUCT_GET_ERROR:
      return {
        ...state,
        product: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducers;
