import { useEffect, useState, useContext } from 'react';
import Head from 'next/head';
import classNames from 'classnames';
import Iframe from 'react-iframe';
import ReactPannellum from 'react-pannellum';

import PageContext from '@contexts/PageContext';
import styles from './Viewer360.module.scss';

const isValidUrl = (str) => {
  var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
      '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(str);
};

const config = {
  autoLoad: true,
  width: '100%',
  height: '100%',
  background: '#ffffff',
  showFullscreenCtrl: true
};

const Viewer360 = ({ Id, DarkMode, subMenuOptions }) => {
  const pageData = useContext(PageContext);
  const [activeTab, setActiveTab] = useState(null);
  const [fileNameUrl, setFileNameUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [isImage, setIsImage] = useState(false);
  const [isSirvSpin, setIsSirvSpin] = useState(false);

  useEffect(() => {
    if (subMenuOptions?.length > 0) {
      const tab = subMenuOptions[0];
      setActiveTab({...tab});
      loadUrls(tab.id);
    }
  }, []);

  const handleTabClick = (e) => {
    e.preventDefault();
    const tab = subMenuOptions?.find((menu) => menu.id === e.target.value);
    if (tab) {
      setActiveTab({...tab});
      loadUrls(e.target.value);
    }
  };

  const loadUrls = (id) => {
    const content = pageData.ProductGallery.find((x) => x.Description == '360')?.GalleryItems.find(
      (item) => item.Id == id,
    )?.ProductGalleryContent;
    setFileNameUrl(content?.FileNameUrl);
    if (isValidUrl(content?.VideoUrl)) {
      setVideoUrl(content?.VideoUrl);
    } else {
      setVideoUrl(null);
    }
  };

  useEffect(() => {
    if (fileNameUrl) {
      fileNameUrl.includes('png') || fileNameUrl.includes('jpg') ? setIsImage(true) : setIsImage(false);
      fileNameUrl.includes('spin') ? setIsSirvSpin(true) : setIsSirvSpin(false);
    }
  }, [fileNameUrl]);

  return (
    <>
      <Head>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/pannellum@2.5.4/build/pannellum.css" />
        <link rel="stylesheet" href="/styles/hotspots.css" />
        <script src="https://cdn.jsdelivr.net/npm/pannellum@2.5.4/build/pannellum.js"></script>
        <script src="https://scripts.sirv.com/sirvjs/v3/sirv.js"></script>
        {videoUrl && (<script src={videoUrl}></script>)}
        <script src="/scripts/jquery-3.5.1.min.js" />
        <script src="/scripts/hotspots.js"></script>
      </Head>
      <div
        className={classNames(
          activeTab?.label.includes('Exterior') ? styles.Wrapper : styles.NoSpinBg,
          DarkMode ? styles.DarkMode : null,
          isSirvSpin ? styles.IsSpin : null,
        )}
      >
        {fileNameUrl && !videoUrl && (
          <>
            {fileNameUrl.includes('sirv.com') && fileNameUrl.includes('.spin') ? (
              <div
                className="Sirv"
                id="srv"
                data-options="thumbnails.type: auto; arrows: false;"
              >
                <div
                  data-src={fileNameUrl}
                  data-type={isImage ? 'zoom' : ''}
                ></div>
              </div>
            ) : fileNameUrl.includes('sirv.com') && (fileNameUrl.includes('.png') || fileNameUrl.includes('.jpg')) ? (
              <ReactPannellum
                key={fileNameUrl}
                id={activeTab?.id}
                sceneId="sirv-panorama"
                imageSource={fileNameUrl}
                config={config}
                width="100%"
                className={styles.PannelContainer}
              />
            ) : (
              <Iframe
                fileNameUrl={fileNameUrl}
                position="absolute"
                width="100%"
                height="100%"
                id={Id}
              />
            )}
          </>
        )}
        {videoUrl && (
          <div
            className="Sirv"
            id="smv"
            data-options="thumbnails.type: auto; arrows: false;"
          >
            <div
              data-type="panorama"
              data-id="pannellum-panorama"
              data-thumbnail-html="<div>Interior</div>"
            >
              <div id="panorama"></div>
              <div className="custom-popup" id="custom-popup">
                <div className="close-popup">
                  <span className="close-popup-button">×</span>
                </div>
                <div className="popup-content"></div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classNames(styles.IconBox, DarkMode ? styles.DarkMode : null)}>
        {subMenuOptions?.map((item) => (
          <button
            key={item.id}
            value={item.id}
            className={`${styles.Button} ${activeTab?.id === item.id ? styles.active : ''}`}
            onClick={handleTabClick}
          >
            {item.label}
          </button>
        ))}
      </div>
    </>
  );
};

export default Viewer360;
