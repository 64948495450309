import React, { Fragment, useEffect, useState, useMemo } from 'react';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import { ParallaxBanner } from 'react-scroll-parallax';
import ReactPlayer from 'react-player';

import { Dimmer } from '@components';
import { getBackupImg } from '@helpers/dataExists';
import styles from './Background.module.scss';

const DynamicImage = dynamic(() => import('@components/DynamicImage'), { ssr: false });

const Background = ({
  className, source, type, options, hasShadow, shadowType, componentTall, isHero, isHome, ...props
}) => {
  const [mediaType, setMediaType] = useState('image');
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setMediaType(type ? type : 'image');
  }, [type]);

  useEffect(async () => {
    await getBackupImg();
  }, []);

  const backgroundMedia = useMemo(() => {
    switch (type || mediaType) {
      case 'image':
        return isHero ? (
          <>
            <ParallaxBanner
              className={styles.HeroParallex}
              layers={[{ image: `${source?.src}`, speed: -7 }]}
              style={{ aspectRatio: '2 / 1', height: '100%' }}
            />

            {/* <div
              className={styles.Parallax}
              style={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${source?.src})`
                //backgroundImage: `url(${source?.src}), url(api/static/media/byfile/Default/Backup/1_N_L_N_I_1366X768.JPG)`
              }}
            /> */}
          </>
        ) : (
          <DynamicImage
            imgSrc={source?.src}
            srcSet={source?.srcSet}
            sizes={isHero && componentTall ? `${source?.width}x600` : isHero && !componentTall ? `${source?.width}x380` : null}
            alt={source?.alt}
          />
        );
      case 'video':
        return (
          <>
            {source?.map(({ type, src }, index) => (
              <Fragment key={index}>
                {src.includes('youtu') || src.includes('vimeo') ? (
                  <div className={src.includes('youtu') ? styles.Wrapper : styles.VimeoWrapper}>
                    <ReactPlayer
                      url={src.includes('youtu') ? src?.replace('youtube.com', 'youtube-nocookie.com') : src}
                      controls={false}
                      width="100%"
                      height="100%"
                      playing={playing}
                      muted={true}
                      pip={false}
                      loop={true}
                      playsinline={true}
                      className={styles.Player}
                      style={{ display: !playing ? 'none' : 'inherit' }}
                      onReady={() => {
                        setPlaying(true);
                      }}
                    />

                    {!playing && <div className={styles.Player} />}
                  </div>
                ) : (
                  <ReactPlayer
                    url={src}
                    controls={false}
                    width="100%"
                    height="100%"
                    playing={playing}
                    muted={true}
                    pip={false}
                    loop={true}
                    playsinline={true}
                    className={styles.Player}
                    style={{ display: !playing ? 'none' : 'inherit' }}
                    onReady={() => {
                      setPlaying(true);
                    }}
                  />
                )}
              </Fragment>
            ))}
          </>
        );
      case 'both':
        return (
          <>
            {props.videoSource?.source?.map(({ type, src }, index) => (
              <React.Fragment key={index}>
                {src.includes('youtu') || src.includes('vimeo') ? (
                  <div className={styles.Wrapper}>
                    <ReactPlayer
                      url={src.includes('youtu') ? src?.replace('youtube.com', 'youtube-nocookie.com') : src}
                      frameborder="0"
                      allow="autoplay;encrypted-media"
                      allowfullscreen
                      controls={false}
                      width="100vw"
                      height="100vh"
                      playing={playing}
                      muted={true}
                      pip={false}
                      loop={true}
                      className={styles.Player}
                      style={{ display: !playing ? 'none' : 'inherit' }}
                      config={{
                        file: {
                          attributes: {
                            poster: props.imageSource?.src
                          }
                        },
                        youtube: {
                          playerVars: { showinfo: 0, playsinline: 1 },
                          enablejsapi: '1'
                        }
                      }}
                      onReady={() => {
                        setPlaying(true);
                      }}
                    />
                    {!playing && <img src={props.imageSource.src} className={styles.Player} />}
                  </div>
                ) : (
                  <ReactPlayer
                    url={src}
                    controls={false}
                    width="100%"
                    height="100%"
                    playing={playing}
                    muted={true}
                    pip={false}
                    loop={true}
                    playsinline={true}
                    className={styles.Player}
                    style={{ display: !playing ? 'none' : 'inherit' }}
                    onReady={() => {
                      setPlaying(true);
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </>
        );
    }
  }, [type, mediaType, isHero, source?.src, props.videoSource, playing]);

  return (
    <div className={classNames(styles.Background, className)}>
      {backgroundMedia}
      {options?.overlay && <Dimmer />}
      {hasShadow ? (
        <div
          className={classNames(
            shadowType == 'DefaultShadow' ? styles.DefaultShadow : shadowType == 'BottomShadow' ? styles.BottomShadow : styles.DarkerShadow
          )}
        ></div>
      ) : null}
    </div>
  );
};

export default Background;
