import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ToastShow() {
    /*
     * @desc
     * @return [toast function] List
     */
    function success(message) {
        toast.success(message, {
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }
    function error(message) {
        toast.error(message, {
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }
    return {
        success,
        error,
    };
}
