import classNames from 'classnames';
import React from 'react';
import Link from 'next/link';
import styles from './Breadcrumbs.module.scss';
import { useRouter } from 'next/router';

const Breadcrumbs = ({ className, items, DarkMode }) => {
  let breadcrumbs = [...items].reverse();
  const trail = [];
  const router = useRouter();
  const { locale } = router;


  if (breadcrumbs[0]?.ProductCode?.toLowerCase() === 'equipment') {
    trail.push('products');
    breadcrumbs = breadcrumbs.slice(2, 4);
  }

  return (
    <ol className={classNames(styles.List, className, DarkMode ? styles.DarkMode : null)}>
      <li className={styles.Item}>
        <Link href="/" prefetch={false}>
          <a className={styles.Crumb}>{locale == 'fr'? 'Accueil' : 'Home'}</a>
        </Link>
      </li>
      {breadcrumbs.map((breadcrumb, index) => {
        trail.push(breadcrumb.ProductCode?.toLowerCase());
        return index + 1 === breadcrumbs.length ? (
          <li className={classNames(styles.Item, styles.Current)} key={index}>
            <span className={styles.Crumb}>
              {breadcrumb.Title ? breadcrumb.Title : breadcrumb.ProductCode}
            </span>
          </li>
        ) : (
          <li className={classNames(styles.Item)} key={index}>
            <Link href={`/${trail.join('/')}`} prefetch={false}>
              <a className={styles.Crumb}>
                {breadcrumb.Title ? breadcrumb.Title : breadcrumb.ProductCode}
              </a>
            </Link>
          </li>
        );
      })}
    </ol>
  );
};

export default Breadcrumbs;
