/**
 * Menu Selectors
 */


/**
* Direct selector to the Main Menu in state
*/
export const useMenuSelector = (state) => {
  return state.menu.items
}


/**
* Direct selector to the Page Error in state
*/
export const useMenuErrorSelector = (state) => {
  if (!process.browser) return null;
  return state.menu.error
}



export default {
  useMenuSelector,
  useMenuErrorSelector
}
