import React from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useMediaQuery } from 'react-responsive';

import { Default, Mobile, Tablet, Desktop, LargerDesktop } from '@helpers/breakpoints';
import { Background, Breadcrumbs, Container } from '@components';
import styles from './Hero.module.scss';

const DynamicImage = dynamic(() => import('@components/DynamicImage'), {
  ssr: false
});

const Title = ({ className, children, ...props }) => {
  return (
    <h1 className={classNames(styles.Title, className)} {...props}>
      {children}
    </h1>
  );
};

const SubTitle = ({ className, children, ...props }) => {
  return (
    <h4 className={classNames(styles.subTitle, className)} {...props}>
      {children}
    </h4>
  );
};

const HDate = ({ className, children, ...props }) => {
  return (
    <label className={classNames(styles.heroDate, className)} {...props}>
      {children}
    </label>
  );
};

const PostedBy = ({ className, children, ...props }) => {
  return (
    <label className={classNames(styles.postedBy, className)} {...props}>
      {children}
    </label>
  );
};

const Label = ({ className, children, ...props }) => {
  return (
    <h2 className={classNames(styles.Label, className)} {...props}>
      {children}
    </h2>
  );
};

const Text = ({ className, children, ...props }) => {
  return (
    <div className={classNames(styles.Text, className)} {...props}>
      {children}
    </div>
  );
};

const LargeDescription = ({ className, children, ...props }) => {
  return (
    <div className={classNames(styles.LargeDescription, className)} {...props}>
      {children}
    </div>
  );
};

const SellPrice = ({ className, children, ...props }) => {
  return (
    <div className={classNames(styles.SellPrice, className)} {...props}>
      {children}
    </div>
  );
};

const ButtonsContainer = ({ className, children, ...props }) => {
  return (
    <div className={classNames(styles.ButtonsContainer, className)} {...props}>
      {children}
    </div>
  );
};

const Hero = ({
  isFormElements,
  background,
  breadcrumbs,
  className,
  children,
  image,
  shadowType,
  hasShadow,
  specialBlurbIsAvailable,
  specialBlurbText,
  alt,
  type,
  homepage,
  postpage,
  DarkMode,
  pageType,
  componentTall,
  hasBackgroundImage,
  hasBackgroundVideo,
  productCode,
  badge,
  showTitle,
  componentTitle,
  openTermConditionModal,
  ...props
}) => {
  const router = useRouter();
  const { locale } = router;

  const showTermConditionModal = () => {
    if (openTermConditionModal) {
      openTermConditionModal()
    }
  };

  return (
    <div
      className={classNames(styles.Hero, className, {
        [styles.HasShadow]: hasShadow,
        [styles.ProductGroup]: pageType == 'ProductGroup' ? true : false,
        [styles.ProductPage]: pageType == 'ProductPage' ? true : false,
        [styles.CategoryGroup]: pageType == 'CategoryGroup' ? true : false,
        [styles.PostPage]: pageType == 'PostPage' ? true : false,
        [styles.WithBackground]: !isFormElements && background,
        [styles.Homepage]: pageType == 'HomePage' ? true : false,
        [styles.DarkMode]: DarkMode == true ? true : false,
        [styles.componentTall]: componentTall == true ? true : false,
        [styles.NoBackground]: background == null && !componentTall && !image ? true : false
      })}
      {...props}
    >
      {background && (
        <Background
          {...background}
          hasBackgroundImage={hasBackgroundImage}
          componentTall={componentTall}
          isHero={true}
          hasBackgroundVideo={hasBackgroundVideo}
          hasShadow={hasShadow}
          type={type}
          shadowType={shadowType}
        />
      )}
      <Container
        className={classNames(
          styles.Container,
          homepage ? styles.HeroHomePage : null,
          breadcrumbs ? null : styles.NoBreadcrumbs
          // type !== 'video' ? styles.ImageConatiner : styles.VideoContainer
        )}
      >
        {breadcrumbs && (
          <Breadcrumbs
            className={classNames(
              styles.Breadcrumbs,
              postpage && background == null ? styles.SpecialBreadcrumb : postpage && background != null ? styles.PostPageBreadcrumb : null
            )}
            items={breadcrumbs}
            DarkMode={DarkMode}
          />
        )}
        <div className={styles.Content}>{children}</div>
        {((image && type != 'video' && image !== undefined) || badge?.DesktopBadge) && (
          <Desktop>
            <div className={styles.ProductImage}>
              <div className={styles.ImageContainer}>
                {image && type != 'video' && image !== undefined && (
                  <Image className={styles.Image} src={image} layout="fill" alt={alt} />
                )}
              </div>
              {specialBlurbIsAvailable && <span className={styles.Legal}>*{specialBlurbText}</span>}

              {badge?.DesktopBadge ? (
                <div className={styles.BadgeContainer} onClick={showTermConditionModal}>
                  <DynamicImage imgSrc={`/api/static/media/byFile${badge?.DesktopBadgePath[locale]}`} />
                </div>
              ) : null}
            </div>
          </Desktop>
        )}
        {image && type != 'video' && image !== undefined && (
          <Tablet>
            <div className={styles.ProductImage}>
              <div className={styles.ImageContainer}>
                {image && type != 'video' && image !== undefined && (
                  <Image className={styles.Image} src={image} layout="fill" alt={alt} />
                )}
              </div>
              {specialBlurbIsAvailable && <span className={styles.Legal}>*{specialBlurbText}</span>}
            </div>
          </Tablet>
        )}
        {image && type != 'video' && image !== undefined && (
          <Mobile>
            <div className={styles.ProductImage}>
              <div className={styles.ImageContainer}>
                {image && type != 'video' && image !== undefined && (
                  <Image className={styles.Image} src={image} layout="fill" alt={alt} />
                )}
              </div>
              {specialBlurbIsAvailable && <span className={styles.Legal}>*{specialBlurbText}</span>}
            </div>
          </Mobile>
        )}
        {badge?.MobileBadge && (
          <Tablet>
            <div className={styles.Badges} onClick={showTermConditionModal}>
              <DynamicImage imgSrc={`/api/static/media/byFile${badge?.MobileBadgePath[locale]}`} />
            </div>
          </Tablet>
        )}
        {badge?.MobileBadge && (
          <Mobile>
            <div className={styles.Badges} onClick={showTermConditionModal}>
              <DynamicImage imgSrc={`/api/static/media/byFile${badge?.MobileBadgePath[locale]}`} />
            </div>
          </Mobile>
        )}
      </Container>
    </div>
  );
};

Hero.Title = Title;
Hero.SubTitle = SubTitle;
Hero.Label = Label;
Hero.Text = Text;
Hero.LargeDescription = LargeDescription;
Hero.SellPrice = SellPrice;
Hero.PostedBy = PostedBy;
Hero.HDate = HDate;
Hero.ButtonsContainer = ButtonsContainer;

export default Hero;
