import { take, call, put, all, select } from 'redux-saga/effects';

import MenuService from '@services/menuService';

import {
  APP_STARTLOADING,
  APP_STOPLOADING,
  MENU_GET_REQUEST,
  MENU_GET_LOADED,
  MENU_GET_ERROR,
} from '../types';

function* getMenuDataSaga(payload) {
  try {
    //
    const state = yield select((state) => state);
    const menuService = MenuService(state);
    const menu = yield call(menuService.getMenuData, payload);
    yield put({ type: MENU_GET_LOADED, payload: menu });
  } catch (err) {
    const errors = err.payload || err;
    yield put({ type: MENU_GET_ERROR, payload: errors });
  }
}

/*
 * Startup flow to allow concurrent actions to be dispatched
 */
function* startupFlow() {
  while (true) {
    // watching for authentication actions
    const action = yield take([MENU_GET_REQUEST]);

    yield put({ type: APP_STARTLOADING });

    if (action.type === MENU_GET_REQUEST) {
      yield call(getMenuDataSaga, action.payload);
    }

    yield put({ type: APP_STOPLOADING });

    yield action;
  }
}

export default function* MenuSagas() {
  yield all([startupFlow()]);
}
