import React from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import styles from './Button.module.scss';

const Button = ({
  animated,
  outlined,
  light,
  children,
  className,
  href,
  target,
  borderRadius,
  ...props
}) => {
  const contents = (
    <>
      {children}
      {animated && <div className={styles.Overlay}>{children}</div>}
    </>
  );

  if (href) {
    const LinkWrapper = (
      <a
        className={classNames(styles.Button, className, {
          [styles.Animated]: animated,
          [styles.BorderRadius]: borderRadius
        })}
        href={href}
        {...(target && { target })}
        {...props}
      >
        {contents}
      </a>
    );

    return target ? LinkWrapper : <Link href={href} prefetch={false}>{LinkWrapper}</Link>;
  }

  return (
    <button
      className={classNames(styles.Button, className, {
        [styles.Animated]: animated,
        [styles.Outlined]: outlined,
        [styles.Light] : light
      })}
      {...props}
    >
      {contents}
    </button>
  );
};

export default Button;
