import { isFeatureEnabled, getFeatureDisplayValue, checkEnabled } from '@components/OptionalFeature';

export function buildProductStructuredData(data) {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    'name': data?.ProductText?.Title,
    'image': data?.imageURL,
    'description': data?.ProductText?.LargeDescription,
    'brand': 'Kubota Canada',
    'offers': {
      '@type': 'Offer',
      'priceCurrency': 'CAD',
    }
  };
}
