import classNames from 'classnames';
import { motion } from 'framer-motion';

import { Background, Container } from '@components';

import styles from './Hero.module.scss';

const Title = ({ className, children, ...props }) => {
  return (
    <motion.h1 className={classNames(styles.Title, className)} {...props}>
      {children}
    </motion.h1>
  );
};

const Text = ({ className, children, ...props }) => {
  return (
    <div className={classNames(styles.Text, className)} {...props}>
      {children}
    </div>
  );
};

const Hero = ({ background, breadcrumbs, className, children, ...props }) => {
  const enterVariants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
  };
  return (
    <div
      className={classNames(styles.FullscreenHero, className, {
        [styles.WithBackground]: background,
      })}
      {...props}
    >
      {background && <Background {...background} />}
      <Container className={styles.Container}>
        <motion.div
          className={styles.Content}
          initial="initial"
          animate="enter"
          variants={enterVariants}
          transition={{ ease: 'easeIn', duration: 0.3, staggerChildren: 0.2 }}
        >
          {children}
        </motion.div>
      </Container>
    </div>
  );
};

Hero.Title = Title;
Hero.Text = Text;

export default Hero;
