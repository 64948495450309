/**
 * Create the store with redux sagas
 **/

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import stateStorage from '@utils/stateStorage';
import createRootReducer from './reducers';
import rootSagas from './rootSagas';
import { MakeStore, createWrapper, Context } from 'next-redux-wrapper';

const composeWithReduxDevtools = (compose) => {
  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  return process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
};

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const enhancers = [applyMiddleware(...middlewares)];
const composeEnhancers = composeWithReduxDevtools(compose);
const localState = stateStorage.loadState();
const store = createStore(
  createRootReducer(),
  localState,
  composeEnhancers(...enhancers),
);

// Subscribe to current state.
store.subscribe(() => {
  stateStorage.saveState(store.getState());
});

// Make the store
const makeStore = (context) => store;
store.sagaTask = sagaMiddleware.run(rootSagas);

// export an assembled wrapper
export const wrapper = createWrapper(makeStore, { debug: true });
