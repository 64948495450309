/*
 * Apppliction reducer
 * We use immutableJS to ensure immutability on the application { ...statelevel.
 */

import { INITIAL_STATE } from "./initialState";

import {
  APP_STARTUP_LOADED,
  APP_STARTUP_ERROR,
  APP_LANG_LOADED,
} from "../types";

//
const reducers = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case APP_STARTUP_LOADED:
      return {
        ...state,
        isLoaded: true,
        error: null,
      };

    case APP_LANG_LOADED:
      return {
        ...state,
        lang: action.payload,
      };

    case APP_STARTUP_ERROR:
      return {
        ...state,
        error: action.payload,
        errorSource: APP_STARTUP_ERROR,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default reducers;
