import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { MENU_RICH_STYLE } from '@/app-configuration';
import { Default, Mobile, Desktop, isDesktop } from '@helpers/breakpoints';
import { getDealerTiming } from '@/helpers/dealer';
import { translateDay, formatTime } from '@/helpers/language';
import { useSelectedRetailerSelector } from '@stores/locations/selectors';
import { Button, Container, Tabs } from '@components';
import { SubMenu } from '@components/Nav/components';
import MenuIcon from '@assets/icon/menu.svg';
import ArrowIcon from '@assets/icon/arrow-left.svg';
import ArrowRIcon from '@assets/icon/arrow-right-f.svg';
import ArrowDownIcon from '@assets/icon/arrow-down.svg';
import ArrowUpIcon from '@assets/icon/arrow-up.svg';
import StarIcon from '@assets/icon/star_filled.svg';
import MarkerIcon from '@assets/icon/marker.svg';
import styles from './Menu.module.scss';

const Menu = ({
  activeKey,
  activeMenu,
  setActiveMenu,
  title,
  items,
  headerClose,
  setHeaderValue,
  deviceType,
  isMenu,
  url,
  index,
  dealer,
}) => {
  const [isDealerOpen, setDealerOpen] = useState(false);
  const [timing, setTiming] = useState({});
  const myDealer = useSelector(useSelectedRetailerSelector);
  const isDesktopAvailable = isDesktop();

  const router = useRouter();
  const { locale } = router;

  useEffect(() => {
    if (headerClose) {
      setActiveMenu(false);
    }
  }, [headerClose]);

  useEffect(() => {
    if (dealer?.hours && Array.isArray(dealer.hours)) {
      let dealerTimings = getDealerTiming(dealer.hours);
      setTiming(dealerTimings);
    }
  }, [dealer]);

  const isOpen = activeMenu === activeKey;

  const handleClick = () => {
    setActiveMenu(isOpen ? null : activeKey);
    setHeaderValue(true);
  };

  const nestedMenus = items?.filter((item) => item.children?.length > 0);
  const nonNestedItems = items?.filter((item) => item.children?.length === 0 || !item.children);

  const menuAdv = items
    ?.filter((item) => item.children?.length > 0)
    .map((item) => item.children)
    .map((y) => y.filter((x) => x.sequence === 9999 && x.target === 'Post'))
    .flat();

  const singleMenuAdv = items?.filter((item) => item.children?.length > 0).filter((item) => item.sequence === 9999 && item.target == 'Post');

  const useOutsidePopupCloser = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDealerOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsidePopupCloser(wrapperRef);

  return (
    <>
      {!isMenu ? (
        <>
          {title == 'Find a Dealer' || title == 'Localisateur de concessionnaires' ? (
            myDealer && dealer ? (
              <div className={styles.FindDealerWrapper} ref={wrapperRef}>
                <div
                  className={classNames(styles.MenuLabel, styles.FindDealer, styles.Animated, {
                    [styles.MainMenuLabel]: index === 1,
                  })}
                  onClick={() => setDealerOpen(!isDealerOpen)}
                >
                  {locale == 'fr' ? 'Mon Concessionnaire' : 'My Dealer'}
                  <div className={styles.Overlay}>{locale == 'fr' ? 'Mon Concessionnaire' : 'My Dealer'}</div>
                </div>
                {isDealerOpen && (
                  <div className={styles.FindDealerDropdown}>
                    <h3><StarIcon /> {locale == 'fr' ? 'Mon Concessionnaire :' : 'My Dealer:'}</h3>
                    <h4>{myDealer.name}</h4>
                    <div className={styles.ContentAddress}>
                      <p>{myDealer.address1}{(myDealer.address2 ? ` ${myDealer.address2}` : '')},</p>
                      <p>{myDealer.city}, {myDealer.province} {myDealer.postal}</p>
                      <p>{myDealer.phone}</p>
                    </div>
                    <div className={styles.ContentHours}>
                      {timing?.isSameTime && timing.timing.map((m, i) => (
                        <p key={i}>
                          <span>{locale == 'fr' ? translateDay(m.day): m.day}</span>&nbsp;
                          {formatTime(m.open, locale)} {locale == 'fr'? 'à' : 'to'} {formatTime(m.close, locale)}
                        </p>
                      ))}
                      {!timing?.isSameTime && Array.isArray(timing?.timing) && (timing?.timing?.length > 0) && (
                        <Tabs>
                          {Array.isArray(timing.timing) && (timing.timing.length > 0) && timing.timing.map((m, i) => (
                            <Tabs.Tab label={m.department} key={i}>
                              {m.item && m.item.map((mm, index) => (
                                <p key={index}>
                                  <span>{locale == 'fr'? translateDay(mm.day): mm.day}</span>&nbsp;
                                  {formatTime(mm.open, locale)} {locale == 'fr'? 'à' : 'to'} {formatTime(mm.close, locale)}
                                </p>
                              ))}
                            </Tabs.Tab>
                          ))}
                        </Tabs>
                      )}
                    </div>
                    <div className={styles.ContentActions}>
                      <Button
                        href={`/dealer/contact-a-dealer?dealer=${dealer.dealerURLName}`}
                        animated
                      >
                        {locale == 'fr' ? 'Contacter' : 'Contact' }
                      </Button>
                      <Button
                        href={`/find-a-dealer/${dealer.dealerURLName}`}
                        animated
                      >
                        {locale == 'fr' ? `D${String.fromCharCode(233)}tails` : 'Details' }
                      </Button>
                    </div>
                    <div className={styles.ContentLink}>
                      <Link
                        href={url}
                        passHref
                        className={styles.ContentLink}
                        prefetch={false}
                      >
                        <a>
                          {locale == 'fr' ? 'Trouvez un concessionnaire' : 'Find A Dealer' } <ArrowRIcon />
                        </a>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Link href={url ? url : ''} passHref prefetch={false}>
                <a
                  className={classNames(styles.MenuLabel, {
                    [styles.MainMenuLabel]: index === 1,
                    [styles.FindDealer]: isDesktopAvailable,
                    [styles.Animated]: isDesktopAvailable,
                  })}
                >
                  <Desktop><MarkerIcon /> </Desktop>{title}
                  <div className={styles.Overlay}>{title}</div>
                </a>
              </Link>
            )
          ) : (
            <Link href={url ? url : ''} passHref prefetch={false}>
              <a
                className={classNames(styles.MenuLabel, {
                  [styles.MainMenuLabel]: index === 1,
                })}
              >
                {title}
                <div className={styles.Overlay}>{title}</div>
              </a>
            </Link>
          )}
        </>
      ) : (
        <>
          <button
            className={classNames(styles.MenuLabel, {
              [styles.MainMenuLabel]: index === 1,
              [styles.ActiveLabel]: isOpen
            })}
            onClick={() => handleClick()}
          >
            {title === 'More' ||
              (title === 'Plus' && (
                <Default>
                  <MenuIcon className={styles.Icon} />
                </Default>
              ))}
            {title}
            <Default>
              {nestedMenus && isOpen ? (
                <ArrowUpIcon className={classNames(styles.OpenArrowDown, {
                  [styles.RichOpenArrowDown]: MENU_RICH_STYLE,
                })} />
              ) : nestedMenus && !isOpen ? (
                <ArrowDownIcon className={classNames(styles.ArrowDown, {
                  [styles.RichArrowDown]: MENU_RICH_STYLE,
                })} />
              ) : null}
            </Default>

            <Mobile>
              <ArrowIcon className={classNames(styles.Icon, styles.ArrowIcon)} />
            </Mobile>
          </button>
          <AnimatePresence>
            {isOpen && isMenu && (
              <motion.div
                className={classNames(styles.MenuDrawer, {
                  [styles.RichMenuDrawer]: MENU_RICH_STYLE,
                })}
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: {
                    opacity: 1,
                    height: deviceType == 'mobile' ? 'auto' : null,
                    transition: { delayChildren: 0.2 },
                    visibility: 'visible',
                  },
                  collapsed: {
                    opacity: 0,
                    height: '0',
                    transition: { when: 'afterChildren' },
                    visibility: 'hidden',
                  }
                }}
                transition={{
                  ease: 'easeInOut',
                  duration: 0.4
                }}
              >
                <Container className={styles.Container}>
                  <div className={styles.SubMenuContainer}>
                    {nestedMenus?.length > 0 && (
                      <SubMenu
                        designType={nestedMenus?.length == 2 ? 'TwoSubMenu' : null}
                        items={nestedMenus}
                      />
                    )}
                    {nonNestedItems?.length > 0 && (
                      <SubMenu
                        designType="NonSubMenuItems"
                        items={nonNestedItems}
                      />
                    )}
                  </div>

                  {menuAdv?.length > 0 && (
                    <div className={classNames(styles.Promos, {
                      [styles.RichPromos]: MENU_RICH_STYLE,
                    })}>
                      <ul className={styles.MenuAdv}>
                        {menuAdv?.map((adv, indx) => (
                          <li key={indx}>
                            <a href={`/posts${adv.menuDetails.url}`}>
                              <div className={styles.BackgroundContainer}>
                                <img
                                  className={styles.Background}
                                  src={`/api/static/media/byFile${adv.menuDetails.image}`}
                                />
                                <div className={styles.BoxShadow}></div>
                              </div>
                              <div className={styles.Content}>
                                <h2>{adv.menuDetails.title}</h2>
                                <h3 className={styles.PromoSubTitle}>{adv.menuDetails.subtitle}</h3>
                              </div>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {singleMenuAdv?.length > 0 && (
                    <div className={styles.Promo}>
                      <ul className={styles.singleMenuAdv}>
                        {menuAdv?.map((pro, indx) => (
                          <li key={indx}>
                            <a href={`/posts${pro.menuDetails.url}`}>
                              <Desktop>
                                <div className={styles.BackgroundContainer}>
                                  <img
                                    className={styles.Background}
                                    src={`/api/static/media/byFile${pro.menuDetails.image}`}
                                  />
                                  <div className={styles.BoxShadow}></div>
                                </div>
                              </Desktop>
                              <div className={styles.Content}>
                                <h2>{pro.menuDetails.title}</h2>
                                <h3>{pro.menuDetails.subtitle}</h3>
                              </div>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Container>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
    </>
  );
};

export default Menu;
