/*
 * Products reducer
 */
import { INITIAL_STATE } from './initialState';

import {
  DEALER_LOADED,
  DEALER_ERROR,
} from "../types";

// Original State Reducer
const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEALER_LOADED:
      return {
        ...state,
        dealerInfo: action.payload,
        error: false,
      };

    case DEALER_ERROR:
      return {
        ...state,
        dealerInfo: {},
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducers;
