import styles from './Progress.module.scss';
export const Container = ({ animationDuration, children, isFinished}) => {
  return (
   <div className={styles.Container} style={{
       opacity: isFinished ? 0: 1,
       transition: `opacity ${animationDuration}ms linear`,
   }}>
       {children}
   </div>
  );
};

