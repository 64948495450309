import { useState, useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { useMediaQuery } from 'react-responsive';
import { ParallaxProvider } from 'react-scroll-parallax';

import { WEBSITE_URL } from '@/app-configuration';

import 'react-image-gallery/styles/scss/image-gallery.scss';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/assets.css';
import '@styles/global.scss';
import 'swiper/swiper.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import '@styles/addSearch.scss';

import { wrapper } from '@stores/configureStore';
import { useProgressStore } from '@stores/useProgressStore';
import { Progress } from '@components';
import ErrorBoundary from '@components/ErrorBoundary';
import { checkValue } from '@components/OptionalFeature';
import { BackgroundHeroCheckup } from '@/helpers/dataExists';
import {
  buildArticleStructuredData,
  buildPostStructuredData,
  buildProductStructuredData
} from '@jsonld';
import { checkEnabled } from '@components/OptionalFeature';

import { smBreakpoint, lgBreakpoint } from '@styles/styles.module.scss';

const Kubota = ({ Component, pageProps }) => {
  const setIsAnimating = useProgressStore((state) => state.setIsAnimating);
  const isAnimating = useProgressStore((state) => state.isAnimating);
  const [backgrondHeroFetchedUrl, setBackgroundHeroFetchedUrl] = useState('');
  const [heroSeasonalityType, setHeroSeasonalityType] = useState('N');
  const data = pageProps?.data;
  const { locale } = useRouter();
  const router = useRouter();

  const heroSeasonality = data?.DisplayOptions?.find((item) => item?.Name === 'Hero')?.DisplayOptions?.find(
    (x) => x?.DisplayOptionKey === 'MediaSeasonality'
  )?.OptionInformation;
  const ComponentTall = data?.DisplayOptions?.find((item) => item?.Name === 'Hero')?.DisplayOptions?.find(
    (x) => x?.DisplayOptionKey === 'ComponentTall'
  )?.Enabled;
  const backgroundHeroEnabled = checkEnabled(data?.DisplayOptions, 'BackgroundHero');

  useEffect(() => {
    const handleStart = () => {
      setIsAnimating(true);
    };
    const handleStop = () => {
      setIsAnimating(false);
    };

    if (['F', 'N', 'P', 'W'].includes(heroSeasonality)) {
      setHeroSeasonalityType(heroSeasonality);
    } else {
      setHeroSeasonalityType('N');
    }

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  useEffect(async () => {
    if (backgroundHeroEnabled && data?.ProductCode) {
      try {
        const fetchedBgResult = await BackgroundHeroCheckup(
          data?.ProductCode,
          heroSeasonalityType,
          ComponentTall
            ? isDesktop
              ? `1920x650`
              : isTablet
              ? `1366x650`
              : isMobile
              ? `400x550`
              : null
            : isDesktop
            ? `1920x500`
            : isTablet
            ? `1366x500`
            : isMobile
            ? `640x360`
            : null,
        );
        setBackgroundHeroFetchedUrl(fetchedBgResult);
      } catch (error) {
        console.error(`Error loading PostBgHeroCheckup image with ID: ${data?.Id}`, error);
      }
    }
  }, [data?.ProductCode, data?.PageId, data?.Id]);

  let isMobile = useMediaQuery({ maxWidth: parseInt(smBreakpoint) - 1 });
  let isTablet = useMediaQuery({
    minWidth: parseInt(smBreakpoint) + 1,
    maxWidth: parseInt(lgBreakpoint) - 1
  });
  let isDesktop = useMediaQuery({ minWidth: lgBreakpoint });

  // const displayOptions = data?.DisplayOptions;
  const showMetadata = true;
  const metaInfo =  data?.DisplayOptions?.find((x) => x.Name === 'Header');
  let title = checkValue(metaInfo?.DisplayOptions, 'MetaTitle', locale);
  let subtitle = data?.SubTitle ? ` ${data?.SubTitle || ''}` : '';
  let description = checkValue(metaInfo?.DisplayOptions, 'MetaDescription', locale);
  const keyword = checkValue(metaInfo?.DisplayOptions, 'MetaKeywords', locale);

  const PageTypeId = data?.DisplayPage?.PageTypeId;

  let structuredData = null;

  switch (PageTypeId) {
    case 3:
    case 9:
    case 12:
      // Product Page
      structuredData = buildProductStructuredData(data);
      if (!title) {
        title = data?.ProductText?.Title;
      }
      description = data?.ProductText?.SmallDescription || description;
      subtitle = data?.ProductText?.SubTitle ? ` ${data?.ProductText?.SubTitle}` : subtitle;

      break;
    case 4:
      // Product group Page
      data.imageURL = backgrondHeroFetchedUrl;
      structuredData = buildProductStructuredData(data);
      if (!title) {
        if (data?.location) {
          title = data.location?.name;
        } else {
          title = data?.ProductText?.Title || (locale === 'fr' ? data?.PostText[1].Subject : data?.PostText[0].Subject);
        }
      }
      description = data?.ProductText?.SmallDescription || description;
      subtitle = data?.ProductText?.SubTitle ? ` ${data?.ProductText?.SubTitle}` : subtitle;
      break;
    case 8: {
      // Article Page
      if (!title) {
        title = locale === 'fr' ? data?.PostText[1].Subject : data?.PostText[0].Subject;
      }
      const shortDescription = locale === 'fr' ? data?.PostText[1].ShortDescription : data?.PostText[0].ShortDescription;
      description = shortDescription || description;
      if (!description) {
        description = locale === 'fr' ? data?.PostText[1].PostTextBody : data?.PostText[0].PostTextBody;
        description = (description || '').replace(/<[^>]+>/g, '');
      }
      structuredData = buildArticleStructuredData(data);
      break;
    }
    case 1:
    case 10:
      // products Page
      if (!title) {
        if (data?.location) {
          title = data.location?.name;
        } else {
          title = locale === 'fr' ? data?.PostText[1].Subject : data?.PostText[0].Subject;
        }
      }
      if (!description) {
        description = locale === 'fr' ? data?.PostText[1].ShortDescription : data?.PostText[0].ShortDescription;
      }
      structuredData = buildPostStructuredData(data);
      break;
    case 0:
      if (!data.category || !data.product) {
        if (!title) {
          title = locale === 'fr' ? data?.PostText[1].Subject : data?.PostText[0].Subject;
        }
        if (!description) {
          description = locale === 'fr' ? data?.PostText[1].ShortDescription : data?.PostText[0].ShortDescription;
        }
        structuredData = buildPostStructuredData(data);
      }

      if (data.PageName && data.PageName === 'UsedMachineryCategories') {
        title = locale === 'fr' ? "Machinerie d'occasion" : 'Used Equipment';

        if (data?.categoryDetail) {
          const category = `${locale === 'fr' ? data?.categoryDetail?.category_FR : data?.categoryDetail?.category_EN}`;
          if (category) {
            title += ` - ${category}`;
          }
        }
      }

      if (data.PageName && data.PageName === 'UsedMachineryProduct' && data?.productDetail) {
        title =
          locale === 'fr'
            ? `${data?.productDetail?.year || ''} ${data?.productDetail?.make || ''} ${data?.productDetail?.model || ''}`
            : `${data?.productDetail?.year || ''} ${data?.productDetail?.make || ''} ${data?.productDetail?.model || ''}`;
        description = data?.productDetail?.comments;
      }
  }

  return (
    <>
      <Head>
        {showMetadata && title && <title>{title}{subtitle} | Kubota Canada</title>}
        {showMetadata && description && <meta name="description" content={description} />}

        <meta property="og:type" content="website" />
        <meta property="og:url" content={WEBSITE_URL} />

        {showMetadata && title && (
          <meta
            property="og:title"
            content={`${title}${subtitle} | Kubota Canada`}
          />
        )}
        {showMetadata && description && <meta property="og:description" content={description} />}
        {showMetadata && keyword && <meta name="keywords" content={keyword} />}
        {showMetadata && subtitle && (
          <meta
            name="addsearch-custom-field"
            data-type="text"
            content={`category=${subtitle.trim()}`}
            key='category'
          />
        )}

        {structuredData && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
          />
        )}

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              window.addsearchConfigurationShared = {
                "lang": "${locale}"
              };
              window.window.searchResultsPageUrl = "${locale === 'fr' ? '/fr/search-results' : '/search-results'}";
            `
          }}
        />
      </Head>

      <Progress isAnimating={isAnimating} />
      <ParallaxProvider>
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
      </ParallaxProvider>
    </>
  );
};

export default wrapper.withRedux(appWithTranslation(Kubota));
