import API from '@utils/api';

export default function FooterService(state) {
  /*
   * @desc
   * @return { footer } | State
   */

  // Return a footer site menu
  async function getFooterData(payload) {
    const postId = payload?.OptionInformation || payload;
    const response = await API.post('/api/footer', { postId });
    return response;
  }

  // Return a footer menu
  async function getFooterMenuData(payload) {
    let menuId = payload?.OptionInformation;
    const response = await API.post('/api/footerMenu', { menuId });
    return response;
  }

  return {
    getFooterData,
    getFooterMenuData
  };
}
