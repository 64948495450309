/**
 * Product Selectors
 */



/**
 * Direct selector to Used products in state
 */
export const useUsedSelector = (state) => {
  return state.used.products
}



export default { useUsedSelector }
