import classNames from 'classnames';
import React, { useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import ArrowIcon from '@assets/icon/arrow-left.svg';
import { reqCompareBarAction } from '@stores/compareProduct/actions';
import styles from './Accordion.module.scss';
import { isComparePopupOpen } from '@/stores/compareProduct/selectors';

const AccordionContext = React.createContext(null);

const Item = ({
  tabClassName,
  arrowClassName,
  contentClassName,
  children,
  title,
  compareBoxCheck = null,
  eventKey,
}) => {
  const [expanded, setExpanded] = useState(true); // true to expand initially  
  const isCompareOpen = useSelector(isComparePopupOpen);
  const dispatch = useDispatch();
  
  const onExpand = () => {
    if (compareBoxCheck) {
      dispatch(reqCompareBarAction());
    } else {
      setExpanded(!expanded)
    }
  }
  return (
    <AccordionContext.Consumer>
      {({ /*expanded, setExpanded,*/ alwaysOpen } = {}) => (
        <>
          <dt
            className={classNames(styles.Tab, tabClassName)}
            onClick={() => onExpand()}
          >
            {title}
            <span
              className={classNames(styles.TabArrow, arrowClassName, {
                [styles.Expanded]: compareBoxCheck ? isCompareOpen : expanded
              })}
            >
              <ArrowIcon />
            </span>
          </dt>
          <AnimatePresence initial={false}>
            {(alwaysOpen || compareBoxCheck ? isCompareOpen : expanded) && (
              <motion.dd
                className={styles.Panel}
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: 'auto' },
                  collapsed: { opacity: 0, height: 0 }
                }}
                transition={{
                  duration: 0.4
                }}
              >
                <div className={classNames(styles.Content, contentClassName)}>{children}</div>
              </motion.dd>
            )}
          </AnimatePresence>
        </>
      )}
    </AccordionContext.Consumer>
  );
};

const Accordion = ({ className, children, defaultActiveKey = null }) => {
  const [expanded, setExpanded] = useState(defaultActiveKey);
  return (
    <AccordionContext.Provider value={{ expanded, setExpanded }}>
      <dl className={classNames(styles.Accordion, className)}>{children}</dl>
    </AccordionContext.Provider>
  );
};

Accordion.Item = Item;

export default Accordion;
