import { take, call, put, all, select } from 'redux-saga/effects';
import FooterService from '@services/footerServices';

import { APP_STARTLOADING, FOOTER_GET_LOADED, FOOTER_GET_REQUEST, FOOTER_MENU_GET_LOADED, FOOTER_MENU_GET_ERROR } from '../types';

function* getFooterDataSaga(payload) {
  try {
    const state = yield select((state) => state);
    const footerService = FooterService(state);
    const footer = yield call(footerService.getFooterData, payload);
    yield put({ type: FOOTER_GET_LOADED, payload: footer });
    const { DisplayOptions } = footer;

    //  Footer Menu  DisplayOptions
    const footerMenuDisplayOptions = DisplayOptions?.find((item) => item.Name == 'Menu')?.DisplayOptions;

    //  Footer Menu Items
    const footerPayload = footerMenuDisplayOptions?.find((item) => item.DisplayOptionKey == 'MenuId');
    const footerMenu = yield call(footerService.getFooterMenuData, footerPayload);
    yield put({ type: FOOTER_MENU_GET_LOADED, payload: footerMenu });
  } catch (error) {
    const errors = error.payload || error;
    yield put({ type: FOOTER_MENU_GET_ERROR, payload: errors });
  }
}

/*
 * Startup flow to allow concurrent actions to be dispatched
 */
function* startupFlow() {
  while (true) {
    // watching for authentication actions
    const action = yield take([FOOTER_GET_REQUEST]);

    yield put({ type: APP_STARTLOADING });

    if (action.type === FOOTER_GET_REQUEST) {
      yield call(getFooterDataSaga, action.payload);
    }

    yield action;
  }
}

export default function* FooterSagas() {
  yield all([startupFlow()]);
}
