import {
  FOOTER_GET_REQUEST,
} from "../types";

export const reqFooterAction = (footerItems) => ({
  type: FOOTER_GET_REQUEST,
  payload: footerItems
});
export default {
  reqFooterAction,
};
