/**
 * Product Selectors
 */



/**
 * Direct selector to Used products in state
 */
export const compareProductSelector = (state) => {
  return state.compareProduct.code
}
export const getCompareProductsSelector = (state) => {
  return state.compareProduct.products
}
export const threeSixtySelector = (state) => {
  return state.compareProduct.viewer
}
export const isComparePopupOpen = (state) => {
  return state.compareProduct.isCompareOpen
}


export default { compareProductSelector, getCompareProductsSelector, threeSixtySelector ,isComparePopupOpen }
