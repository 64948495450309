import classNames from 'classnames';

import styles from './Card.module.scss';

const Title = ({ as: Component = 'h2', className, children, ...props }) => {
  return (
    <Component className={classNames(styles.Title)} {...props}>
      {children}
    </Component>
  );
};

const SubTitle = ({ as: Component = 'h4', className, children, ...props }) => {
  return (
    <Component className={classNames(styles.SubTitle)} {...props}>
      {children}
    </Component>
  );
};

const Body = ({ as: Component = 'div', className, children, ...props }) => {
  return (
    <Component className={classNames(styles.Body, className)} {...props}>
      {children}
    </Component>
  );
};

const Text = ({ as: Component = 'div', className, children, ...props }) => {
  return (
    <Component className={classNames(styles.Text, className)} {...props}>
      {children}
    </Component>
  );
};

const Media = ({ className, children, ...props }) => {
  return (
    <div className={classNames(styles.Media)} {...props}>
      {children}
    </div>
  );
};

const More = ({ as: Component = 'div', className, children, ...props }) => {
  return (
    <Component className={classNames(styles.More, className)} {...props}>
      {children}
    </Component>
  );
};

const Card = ({
  className,
  children,
  title,
  tag: Component = 'div',
  ...props
}) => {
  return (
    <Component className={classNames(styles.Card, className)} {...props}>
      {children}
    </Component>
  );
};

Card.Body = Body;
Card.Title = Title;
Card.SubTitle = SubTitle;
Card.Text = Text;
Card.Media = Media;
Card.More = More;

export default Card;
