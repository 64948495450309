import API from '@utils/api';

export default function dealerService(state) {
  /*
   * @desc
   * @return [dealerinfo] List
   */
  async function getDealerInfo({ dealerCode }) {
    const response = await API.post('/api/dealerInfo', { dealerCode });
    return response;
  }

  return {
    getDealerInfo
  };
}
