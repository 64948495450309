
/**
 * Used Products initial values for the redux state.
 */
export const INITIAL_STATE = {
  code: [],
  products: [],
  viewer: false,
  isCompareOpen: true,
}
