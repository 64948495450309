import { useState } from 'react'
import classNames from 'classnames';
import { Header, Footer } from '@components';
import styles from './Layout.module.scss';

const Layout = ({ className, children, DarkMode }) => {
  const [headerClose, setHeaderClose] = useState(true);

  const headerValue = () => {
    setHeaderClose(false)
  };

  return (
    <div>
      {!headerClose ? (
        <div
          style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 10 }}
          onClick={() => setHeaderClose(!headerClose)}
        >
        </div>
      ) : null}
      <div className={classNames(styles.Wrapper, className, DarkMode ? styles.DarkMode : null)}>
        <Header headerClose={headerClose} setHeaderValue={headerValue} />
        <main className={styles.Body}>{children}</main>
        <Footer darkMode={DarkMode} />
      </div>
    </div>
  );
};

export default Layout;