import { take, call, put, all, select } from "redux-saga/effects";
// import { delay } from 'redux-saga'


import {
  APP_STARTUP_REQUEST,
  APP_STARTUP_ERROR,
  APP_STARTLOADING,
  APP_STOPLOADING,
  APP_LANG_REQUEST,
  APP_LANG_LOADED,
} from "../types";

function* startup(payload) {
  try {
    // yield call(delay, 5000)
    // yeild payload here

  } catch (err) {
    const errors = err.payload || err;
    yield put({ type: APP_STARTUP_ERROR, payload: errors });
  }
}


// Update global language toggle.
function* changeLanguageSaga(payload) {
  try {
    
    yield put({ type: APP_LANG_LOADED, payload:payload.lang });

  } catch (err) {
    const errors = err.payload || err;
    yield put({ type: APP_STARTUP_ERROR, payload: errors });
  }
}




/*
 * Startup flow to allow concurrent actions to be dispatched
 */
function* startupFlow() {
  while (true) {
    // watching for actions
    const action = yield take([
      APP_STARTUP_REQUEST,
      APP_LANG_REQUEST
    ]);

    yield put({ type: APP_STARTLOADING })

    if (action.type === APP_STARTUP_REQUEST) {
      yield call(startup, action.payload);
    }

    if (action.type === APP_LANG_REQUEST) {
      yield call(changeLanguageSaga, action.payload);
    }

    yield put({ type: APP_STOPLOADING });

    yield action;
  }
}

export default function* AppSagas() {
  yield all([startupFlow()]);
}
