import React from 'react';
import { imageExists } from '@helpers/dataExists';

export default function DynamicImage(props) {
  const replaceImgWithError = (event) => {
    imageExists(event, props.sizes);
  };

  return (
    <img
      className={props?.className}
      onError={replaceImgWithError}
      layout={props?.layout}
      alt={props?.alt}
      srcSet={props?.srcSet}
      src={props.imgSrc}
      sizes={props?.sizes}
    />
  );
}
