import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import styles from './CheckboxInput.module.scss';
import { Mobile } from '@/helpers/breakpoints';

const CheckboxInput = ({ className, id, name, checked = false, title, value, onChange, disabled, DarkMode, ...props }) => {
  const handleChange = (e) => {
    if (onChange) onChange(e);
  };

  return (
    <label htmlFor={id} className={classNames(styles.Label, className, DarkMode ? styles.DarkMode : null)} {...props}>
      <Mobile>
        <span className={styles.FakeSpace}></span>
      </Mobile>
      <div className={styles.CheckBoxContainer}>
        <input
          className={styles.Input}
          disabled={disabled}
          type="checkbox"
          {...{
            id,
            name,
            value
          }}
          checked={checked}
          onChange={handleChange}
        />
        <span className={styles.Checkbox}>{checked && <FontAwesomeIcon icon={faCheck} />}</span>
        <span dangerouslySetInnerHTML={{ __html: (title) }} />
      </div>
    </label>
  );
};

export default CheckboxInput;
