/**
 * Menu Selectors
 */


/**
* Direct selector to the Main Menu in state
*/
export const useFooterSelector = (state) => {  
  return state.footer.items
}

export const useFooterMenuSelector = (state) => {  
  return state.footer.menuItems
}


/**
* Direct selector to the Page Error in state
*/
export const useFooterErrorSelector = (state) => {
  if (!process.browser) return null;
  return state.footer.error
}



export default {
  useFooterSelector,
  useFooterErrorSelector
}
