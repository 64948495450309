/*
 * Products reducer
 */

import { INITIAL_STATE } from "./initialState";

import {
  LOCATIONS_GET_LOADED,
  LOCATIONS_GET_ERROR,
  LOCATIONS_RESET_LOADED,
  MY_DEALER_SAVE_LOADED,
  MY_DEALER_ERROR,
} from "../types";

// Original State Reducer
const reducers = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case LOCATIONS_GET_LOADED:
      return {
        ...state,
        locations: action.payload,
        error: false,
      };

    case MY_DEALER_SAVE_LOADED:
      return {
        ...state,
        location: action.payload,
      };

    case MY_DEALER_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case LOCATIONS_RESET_LOADED:
      return {
        ...state,
        locations: [],
        error: false,
      };

    case LOCATIONS_GET_ERROR:
      return {
        ...state,
        locations: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducers;
