import raygun from 'lasergun';
import { isNil, isEmpty, get, includes, upperCase } from 'lodash-es';

import { BUILD_TARGET, RAYGUN_API_KEY } from '@/app-configuration';

const isNodeEnvironment = typeof global !== 'undefined';
const isBrowserEnvironment = typeof window !== 'undefined';

const environmentData = {
  isServerSideError: isNodeEnvironment,
  isClientSideError: isBrowserEnvironment,
  applicationEnvironment: [BUILD_TARGET.toLowerCase(), 'kubota.ca'].join('.')
};

// Collect error details for raygun reporting
function getRaygunReportingErrorDetails(error) {
  const appendError = (errorMessage, errorDetails) => (isEmpty(errorMessage) ? errorDetails : `${errorMessage}. ${errorDetails}`.trim());

  let detailedErrorMessage = '';

  if (typeof error === 'string') {
    return error;
  }

  if (error && !error.response && error.message) {
    detailedErrorMessage = appendError(detailedErrorMessage, error.message);
    return detailedErrorMessage;
  }

  if (!error || !error.response) {
    return detailedErrorMessage;
  }

  const { statusText, data, config } = error.response;

  if (config) {
    const { method, url, data } = config;

    if (method) {
      detailedErrorMessage = appendError(detailedErrorMessage, upperCase(method));
    }

    if (url) {
      detailedErrorMessage = appendError(detailedErrorMessage, url);
    }

    if (data) {
      detailedErrorMessage = appendError(detailedErrorMessage, data);
    }
  }

  if (data) {
    const { message } = data;

    detailedErrorMessage = appendError(detailedErrorMessage, data);

    if (message) {
      detailedErrorMessage = appendError(detailedErrorMessage, message);
    }
  }

  if (statusText) {
    detailedErrorMessage = appendError(detailedErrorMessage, statusText);
  }

  return detailedErrorMessage;
}

function setErrorDetails(error) {
  // Add more details for easier debugging / troubleshooting
  if (!get(error, 'detailedErrorMessage', null)) {
    const detailedErrorMessage = getRaygunReportingErrorDetails(error);

    if (detailedErrorMessage) {
      (error.message = isEmpty(error?.message) ? detailedErrorMessage : [error.message, detailedErrorMessage]).join(' >> ');
    }
  }

  return error;
}

// Log error and flag it to avoid redundant logging
export function logError(error) {
  if (error && !error.isLogged) {
    console.error(error.message);
    error.isLogged = true;
  }

  return error;
}

function reportRaygunError(error) {
  if (isNil(error) || get(error, 'response.status') === 401) {
    return;
  }

  // Report error if Raygun API key is provided
  if (isEmpty(RAYGUN_API_KEY)) {
    console.debug('No Raygun key set. Error reporting will be skipped.');
  } else {
    setErrorDetails(error);

    // Log error details & stack trace
    raygun().apiKey(RAYGUN_API_KEY).data(environmentData).send(error);
  }
}

export function logAndReportError(error) {
  const statusCode = get(error, 'response.status', null);
  const ignoreErrorStatusCodes = get(error, 'response.config.ignoreErrorStatusCodes', []);
  const ignoreErrorReporting = !isEmpty(ignoreErrorStatusCodes) && includes(ignoreErrorStatusCodes, statusCode);

  logError(error);

  if (!ignoreErrorReporting) {
    reportRaygunError(error);
  }

  return error;
}

export function enableRaygunErrorReporting() {
  if (isBrowserEnvironment) {
    // Add global error reporting for uncaught JS errors
    window.onerror = (message, source, lineno, colno, error) => {
      if (!error) {
        error = {};
      }

      // Set error message if empty
      if (message && isEmpty(error.message)) {
        error.message = message;
      }

      // Add more details for debugging
      setErrorDetails(error);

      error.debug = { source, lineno, colno };

      logError(error);

      reportRaygunError(error);
    };
  }
}
