import styles from './Progress.module.scss';
export const Bar = ({ animationDuration, progress }) => {
  return (
    <div
      className={styles.Bar}
      style={{
        marginLeft: `${(-1 + progress) * 100}%`,
        transition: `margin-left ${animationDuration}ms linear`,
      }}
    ></div>
  );
};

