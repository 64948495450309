import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { defaultTo } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import PageContext from '@contexts/PageContext';
import { Button, Container } from '@components';
import { getLanguageId } from '@helpers/language';
import { reqFooterAction } from '@stores/footer/actions';
import { useFooterSelector, useFooterMenuSelector } from '@stores/footer/selectors';
import API from '@utils/api';
import { checkEnabled } from '@components/OptionalFeature';
import ToastShow from '@services/toastService';

import LogoStatement from '@assets/for-earth-for-life.svg';
import navstyles from '../Nav/Nav.module.scss';
import styles from './Footer.module.scss';
import { apiClient } from '@/http-client';

library.add(fab);

const Footer = ({ darkMode }) => {
  const [email, setEmail] = useState('');
  const [isNewsSubmitLoader, setNewsSubmitLoader] = useState(false);
  const router = useRouter();
  const { locale, locales } = router;
  const dispatch = useDispatch();
  const dataPage = useContext(PageContext);
  const { DisplayOptions } = dataPage;
  const footerItems = useSelector(useFooterSelector);
  const footerMenuItems = useSelector(useFooterMenuSelector);
  const { DisplayOptions: subDisplayOptions } = footerItems;
  const [footerData, setFooterData] = useState({});
  const [menuData, setMenuData] = useState([{}]);

  // check show footer
  const footerOptions = DisplayOptions?.find((x) => x.Name == 'Footer')?.DisplayOptions;

  const showFooter = checkEnabled(footerOptions, 'DisplayComponent');

  // footer data
  const footerPostId = dataPage?.DisplayOptions?.find((x) => x.Name == 'Footer')?.DisplayOptions?.find(
    (item) => item.DisplayOptionKey == 'FooterPostId' || item.DisplayOptionKey == 'PostId'
  )?.OptionInformation;

  // copyRight of footer
  const copyRightText = footerData?.DisplayOptions?.find((x) => x.Name == 'Body')?.DisplayOptions?.find(
    (item) => item.DisplayOptionKey == 'CopywriteText'
  )?.OptionInformation[locale];

  // show footer bg
  const showBackground = footerData?.DisplayOptions?.find((x) => x.Name == 'Body')?.DisplayOptions?.find(
    (item) => item.DisplayOptionKey == 'Background'
  )?.Enabled;

  // English text
  const englishText = footerData?.DisplayOptions?.find((x) => x.Name == 'Body')?.DisplayOptions?.find(
    (item) => item.DisplayOptionKey == 'EnglishLocalizationText'
  )?.OptionInformation[locale];

  // French text
  const frenchText = footerData?.DisplayOptions?.find((x) => x.Name == 'Body')?.DisplayOptions?.find(
    (item) => item.DisplayOptionKey == 'FrenchLocalizationText'
  )?.OptionInformation[locale];

  // Subscription Enabled
  const subscriptionEnabled = footerData?.DisplayOptions?.find((x) => x.Name == 'Subscription')?.DisplayOptions?.find(
    (item) => item.DisplayOptionKey == 'DisplayComponent'
  )?.Enabled;

  // Subscription Title
  const subscriptionTitle = footerData?.DisplayOptions?.find((x) => x.Name == 'Subscription')?.DisplayOptions?.find(
    (item) => item.DisplayOptionKey == 'SectionTitle'
  )?.OptionInformation[locale];

  // Menu
  const menuId = footerData?.DisplayOptions?.find((x) => x.Name == 'Menu')?.DisplayOptions?.find(
    (item) => item.DisplayOptionKey == 'MenuId'
  )?.OptionInformation;

  const StayConnectedText = menuData
    .find((x) => x.Name === 'StayConnected')
    ?.MenuTexts?.find((x) => parseInt(x.Language) === getLanguageId(locale))?.Title;

  useEffect(() => {
    fetchFooterData();
    dispatch(reqFooterAction(footerPostId));
  }, []);

  useEffect(() => {
    fetchMenuData();
  }, [footerData]);

  const changeKeyword = (event) => {
    setEmail(event.target.value);
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let showToast = ToastShow();
    let data = {
      type: 'FormData',
      fieldValues: [
        {
          type: 'FieldValue',
          id: '749',
          name: 'Email Address',
          value: email.toString()
        },
        {
          type: 'FieldValue',
          name: 'First Name',
          id: '750',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'Last Name',
          id: '751',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'Business Phone',
          id: '752',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'Address1',
          id: '753',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'city',
          id: '754',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'Zip or Postal Code',
          id: '755',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'State or Province',
          id: '756',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'Comments',
          id: '758',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'dealercode',
          id: '769',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'leadSource',
          id: '774',
          value: 'website'
        },
        {
          type: 'FieldValue',
          name: 'Interested Year',
          id: '781',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'Interested Stock',
          id: '782',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'Interested Model',
          id: '783',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'Interested Make',
          id: '784',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'Interested Condition',
          id: '785',
          value: ''
          // props.compareProduct.products[0].DisplayOptions[0].DisplayOptions[0].isNew === false ? 'Used' : 'New'
        },
        {
          type: 'FieldValue',
          name: 'dealerName',
          id: '786',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'Product/Solution of Interest',
          id: '790',
          value: ''
        },
        {
          type: 'FieldValue',
          name: 'CLIENT_ID',
          id: '731',
          value: '36'
        },
        {
          type: 'FieldValue',
          name: 'CLIENT_NM',
          id: '732',
          value: 'Kubota'
        },
        {
          type: 'FieldValue',
          name: 'PROCESS_NM',
          id: '733',
          value: 'Lead Routing'
        },
        {
          type: 'FieldValue',
          name: 'INSERT_DT',
          id: '734',
          value: '1999-12-31'
        },
      ]
    };
    if (!email.length > 0) {
      showToast.error('Please enter email address');
      return;
    }
    if (!validateEmail(email)) {
      showToast.error('Please enter a valid email address');
      return;
    }
    setNewsSubmitLoader(true);
    API.post('/api/contactForm', data).then(
      (res) => {
        setNewsSubmitLoader(false);
        setEmail('');
        if (res['id']) {
          showToast.success('Successfully subscribe to Newsletter.');
        } else {
          showToast.error('Error');
        }
      },
      (err) => {
        setNewsSubmitLoader(false);
      }
    );
  };

  const fetchFooterData = async () => {
    let id = footerPostId;
    let data = await API.post('/api/posts', {
      id,
      locale
    });

    setFooterData(data);
  };

  const fetchMenuData = async () => {
    if (!menuId) return;

    try {
      const { data: footerData } = await apiClient.get(`/products/api/Menu/${menuId}`);

      if (footerData && footerData.length > 0) {
        let obj = {};
        let ac = [];
        for (let x of footerData) {
          const { data: subMenuData } = await apiClient.get(`/products/api/Menu/${x?.Id}`);

          obj = x;
          obj['subMenu'] = subMenuData
            .filter((f) => f.Parent === x.Id && f.Status === 1)
            .sort((a, b) => a.Sequence - b.Sequence)
            .map((val) => val);

          ac.push(obj);
          var sortedArray = ac.sort((a, b) => a.Sequence - b.Sequence);
        }

        setMenuData(sortedArray);
      }
    } catch (error) {
      console.error('Fetching footer menu data failed. menuId:', menuId);
    }
  };

  return showFooter ? (
    <footer className={classNames(styles.Footer, darkMode ? styles.DarkMode : null)}>
      {showBackground && (
        <div
          className={styles.Background ? styles.Background : null}
          style={{ backgroundImage: `url(/api/static/media/byfile/posts/${footerData.Id}/post/1_N_N_N_I_2160X1200.JPG)` }}
        />
      )}

      <Container className={styles.Container}>
        <div className={classNames(styles.Column, styles.EmailColumn)}>
          {subscriptionEnabled && (
            <>
              <h2 className={styles.EmailColumnTitle}>{subscriptionTitle}</h2>
              <input onChange={changeKeyword} value={email} className={styles.EmailInput} placeholder="Enter your email" />
              <Button className={`${styles.Submit} ${isNewsSubmitLoader && styles.disabled}`} animated onClick={handleSubmit}>
                Submit
              </Button>
            </>
          )}

          {StayConnectedText && <h4 className={styles.SocialLinksTitle}>{StayConnectedText}</h4>}
          <ul className={styles.SocialLinks}>
            {menuData.length > 0 &&
              menuData
                .find((x) => x.Name === 'StayConnected')
                ?.subMenu?.map((v, i) => {
                  const menuText = v.MenuTexts?.find((x) => parseInt(x.Language) === getLanguageId(locale));
                  return (
                    <li key={i} className={styles.SocialItem}>
                      <a
                        className={styles.SocialIcon}
                        href={menuText?.Url}
                        title={menuText?.SubTitle}
                        alt={menuText?.Description}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', menuText?.ImageUrl]} size="lg" />
                      </a>
                    </li>
                  );
                })}
          </ul>
          <ul className={styles.Fefl}>
            <li>
              <a href="/" className={navstyles.Branding}>
                <LogoStatement className={navstyles.LogoStatement} />
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.CopyrightNotice}>{copyRightText}</div>

        {menuData.length > 0 &&
          menuData
            .filter((x) => x.Name !== 'StayConnected')
            .map((item, index) => {
              return (
                <div key={index} className={classNames(styles.Column, styles.MenuColumn)}>
                  <h2 className={styles.ColumnTitle}>{item.MenuTexts?.find((x) => parseInt(x.Language) === getLanguageId(locale))?.Title}</h2>
                  <nav className={styles.FooterMenu}>
                    <ul className={styles.MenuLeft}>
                      {item?.subMenu?.map((v, i) => {
                        const menuText = v.MenuTexts?.find((x) => parseInt(x.Language) === getLanguageId(locale));

                        return (
                          <li key={i} className={styles.MenuItem}>
                            <Link href={'/' + menuText?.Url} prefetch={false}>
                              <a className={styles.Link}>{menuText?.Title}</a>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                </div>
              );
            })}
        <ul className={styles.MenuRight}>
          {locales.map((localeCode) => (
            <li className={styles.MenuItem} key={localeCode}>
              <Link
                href={localeCode === 'fr' ? `/fr${router.asPath}` : `${router.asPath}`}
                locale={localeCode}
                prefetch={false}
              >
                <a className={styles.LocaleLink}>
                  {localeCode === 'fr' ? defaultTo(frenchText, '') : defaultTo(englishText, '')}
                </a>
              </Link>
            </li>
          ))}
        </ul>
        <ToastContainer />
      </Container>
    </footer>
  ) : null;
};

export default Footer;
