
/**
 * Menu initial values for the redux state.
 */
export const INITIAL_STATE = {
  items: [],
  menuItems:[],
  menuError : false,
  error:false,
}
