import { all } from 'redux-saga/effects'

import AddSagas from './app/sagas'
import ProductSagas from './products/sagas'
import LocationsSagas from './locations/sagas'
import MenuSagas from './menu/sagas'
import UsedProductSagas from './used/sagas'
import DealerSagas from './dealer/sagas'
import FooterSagas  from './footer/sagas'

// single entry point to start all Sagas at once
//
export default function* rootSaga() {
  yield all([
    AddSagas(),
    ProductSagas(),
    LocationsSagas(),
    MenuSagas(),  
    UsedProductSagas(),
    DealerSagas(),
    FooterSagas()
  ]);
}
