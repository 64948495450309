/*
 * Products reducer
 */
import { INITIAL_STATE } from "./initialState";

import {
  ADD_PRODUCT_CODE,
  REMOVE_PRODUCT_CODE,
  UPDATE_PRODUCT_CODE,
  RESET_PRODUCT_CODE,
  ADD_PRODUCT_COMPARE,
  THREE_SIXTY_VIEWER,
  COMPARE_POPUP_SHOW
} from "../types";

// Original State Reducer
const reducers = (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case ADD_PRODUCT_CODE:
      return {
        ...state,
        code: [...state.code, action.payload],
      };
    case REMOVE_PRODUCT_CODE:
      return {
        ...state,
        code: state.code.filter(item => item !== action.payload),
      };
    case UPDATE_PRODUCT_CODE:
      return {
        ...state,
        code: action.payload,
      };
    case RESET_PRODUCT_CODE:
      return {
        ...state,
        code: [],
      };
    case THREE_SIXTY_VIEWER:
      return {
        ...state,
        viewer: action.payload,
      };
    case COMPARE_POPUP_SHOW:
      return {
        ...state,
        isCompareOpen: !state.isCompareOpen,
      };
    case ADD_PRODUCT_COMPARE:
      let addedProducts = [...state.products, action.payload].filter((item, index, arr) => arr.findIndex(f => f.ProductCode == item.ProductCode) == index);
      return {
        ...state,
        products: addedProducts,
      };
    default:
      return state;
  }
};

export default reducers;
