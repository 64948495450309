import API from '@utils/api';
import { getLanguageId } from '@helpers/language';

export default function productService(state) {
  // Return a single product object for state
  async function useProduct(product, locale) {
    return formatProduct(product, locale);
  }

  /*
   * @desc
   * @return { product }
   */
  const formatProduct = ({ product }, locale = 'en') => {
    // Uncomment to see the original page data
    const lang = getLanguageId(locale);

    if (product) {
      const { Features, ProductGallery, ProductText, QuickFeatures, ...data } = product;
      const newProduct = {
        Features: Features.map(({ FeatureText, ...feature }) => ({
          FeatureText: FeatureText?.find((text) => text.LanguageId === lang),
          ...feature
        })),
        ProductGallery: ProductGallery?.map(({ GalleryItems, ...gallery }) => ({
          GalleryItems: GalleryItems?.map(({ ProductGalleryContent, ...galleryItem }) => ({
            ProductGalleryContent: ProductGalleryContent.find((content) => content.Language === lang),
            ...galleryItem
          })),
          ...gallery
        })),
        ProductText: ProductText?.find((text) => text.LanguageId === lang),
        QuickFeatures: QuickFeatures?.map(({ FeatureText, ...feature }) => ({
          FeatureText: FeatureText?.find((text) => text.LanguageId === lang),
          ...feature
        })),
        ...data
      };
      return newProduct;
    }

    return { error: 'No Product found ' };
  };

  /*
   * @desc
   * @return [usedProducts] List
   */
  async function useUsedProducts(query) {
    const response = await API.post('/api/usedProducts', query);
    return response;
  }

  /*
   * @desc
   * @return [formatted ] List
   */
  async function formatUsedProducts(list) {
    const handle = (title) => {
      return title
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
    };

    let categories = [];
    for (let i = 0; i < list.length; i++) {
      const isInCategories = categories.some((p) => {
        return p.id === list[i].categoryId;
      });

      // If it's in the category, don't push it in.
      if (!isInCategories) {
        categories.push({
          handle: handle(list[i].category_EN), // TODO: Localise this string
          name: list[i].category_EN, // TODO: Localise this string
          name_fr: list[i].category_FR,
          id: list[i].categoryId,
          products: [],
          count: 0,
          image: `/api/static/resource/images/${list[i].folderId}/${list[i].image}`
        });
      }
    }

    // Add proucts to categories
    for (let i = 0; i < categories.length; i++) {
      const productsInCategory = list.filter((p) => {
        return p.categoryId === categories[i].id;
      });
      categories[i].products = await usedProductModel(productsInCategory);
      categories[i].count = productsInCategory.length;
    }

    return categories;
  }

  // Used Product Model
  async function usedProductModel(products) {
    let newProducts = [];
    for (let i = 0; i < products.length; i++) {
      const image = `/api/static/resource/images/usedmachinery/${products[i].folderId}/${products[i].image}`;
      const category = products[i].category_EN; // TODO: Add this to globalization

      newProducts.push({
        usedID: products[i].usedID,
        postDate: products[i].post_date,
        expiryDate: products[i].expiry_date,
        modifiedDate: products[i].ModifiedDate,
        solddateon: products[i].solddateon,
        make: products[i].make,
        otherMake: products[i].otherMake,
        model: products[i].model,
        hours: products[i].hours,
        year: products[i].year,
        price: products[i].price,
        categoryId: products[i].categoryId,
        category: category,
        otherCat: products[i].otherCat,
        power: products[i].power,
        image: image,
        folderId: products[i].folderId,
        attachments: products[i].attachments,
        comments: products[i].comments,
        serial: products[i].serial,
        condition: products[i].condition,
        company: products[i].company,
        phone: products[i].phone,
        companyNumber: products[i].companyNumber,
        dealerCode: products[i].DealerCode,
        imageId: products[i].ImageId,
        lat: products[i].Lat,
        lng: products[i].Lng,
        postalCode: products[i].postalCode,
        source: products[i].source,
        stockId: products[i].stockId,
        isCertified: products[i].isCertified || products[i].IsCertified
      });
    }
    return newProducts;
  }

  return {
    useProduct,
    useUsedProducts,
    formatUsedProducts
  };
}
