/**
 * App Selectors
 */


// Direct selector to the app state domain
export const getAppDataSelector = (state) => {  
  return state.app
}


// Direct selector to the app state domain
export const getAppLanguageSelector = (state) => {
  return state.app.lang
}

export default {
  getAppDataSelector,
  getAppLanguageSelector,
}
