import { isString, toString } from 'lodash';
import axios from '@/http-client';
import { WEBSITE_URL } from '@/app-configuration';
import { logAndReportError } from '@/error-reporting';
import { logInfo, logSuccess, logError } from '@/logger';

const toJSON = JSON.stringify;

export default {
  post: async (
    path,
    payload,
    options = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    }
  ) => {
    if (!isString(path)) {
      throw new Error('Invalid API URI:', path);
    }

    const apiURL = [WEBSITE_URL, path].join('');

    let dtStart = Date.now();

    logInfo(`[API Proxy] - API call pending. Method: POST. URL: ${toString(apiURL)}, Payload: ${toJSON(payload)}, Options: ${toJSON(options)}`);

    try {
      const { data } = await axios.post(apiURL, payload, options);
      logSuccess(
        `[API Proxy] - API call succesful. Method: POST. URL: ${toString(apiURL)}, Payload: ${toJSON(payload)}, time: ${
          Date.now() - dtStart
        }, Options: ${toJSON(options)}`
      );
      return data;
    } catch (error) {
      logError(
        `[API Proxy] - API call failed. Method: POST. URL: ${toString(apiURL)}, Payload: ${toJSON(payload)}, Options: ${toJSON(options)}. Error: ${
          error.message
        }`
      );
      logAndReportError(error);
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    }
  }
};
