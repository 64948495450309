/**
 * Locations Selectors
 */



/**
  * Direct selector to the products in state
  */
export const useAllLocationsSelector = (state) => {
  return state.locations
}

/**
  * Direct selector to the selected retailer
  */
export const useSelectedRetailerSelector = (state) => {
  return state.locations.location
}

/**
 * Direct selector to the product[id] in state
 */
export const useLocationSelector = (handle) => {
  return (state) => {
    const { locations } = state.locations
    if (locations) {
      let location = locations.find((local) => {
        return local.handle === handle;
      });
      return location
    }
    return false;
  }
}

export default {
  useAllLocationsSelector,
  useLocationSelector,
  useSelectedRetailerSelector,
}
