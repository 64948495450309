import { getAppLanguageSelector } from '@stores/app/selectors';
import { useSelector, useDispatch } from 'react-redux';

const locales = ['en', 'fr'];

export function getLanguageId(locale) {
  return locales.indexOf(locale);
}

export function getLanguageCode(languageId) {
  return locales[languageId];
}

export function translateDay(day) {
  switch(day) {
    case 'Monday': return "Lundi";
    case 'Tuesday': return "Mardi";
    case 'Wednesday': return "Mercredi";
    case 'Thursday': return "Jeudi";
    case 'Friday': return "Vendredi";
    case 'Saturday': return "Samedi";
    case 'Sunday': return "Dimanche";
  }
}

export function formatTime(time, locale = 'en') {
  const timeArr= time.split(':');
  let hours = timeArr[0];
  let minutes = timeArr[1];
  if (minutes==null) return false;
  
  if (locale === 'en') {
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, '0');
    return `${hours}:${minutes} ${ampm}`;
  } else {
    hours = hours.toString().padStart(2, '0');
    minutes = minutes.toString().padStart(2, '0');
    return `${hours} h ${minutes}`;
  }
}

export function translateFRDepartment(department){
  switch(department) {
    case 'Sales': return 'Ventes';
    case 'Service': return 'Service';
    case 'Rentals': return 'Locations';
    case 'Parts': return 'Pièces';
  }
}