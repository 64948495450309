import {
  MENU_GET_REQUEST,
} from "../types";

export const reqMenuAction = (menuItems) => ({
  type: MENU_GET_REQUEST,
  payload: menuItems
});
export default {
  reqMenuAction
};
