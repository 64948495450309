import { take, call, put, all, select } from 'redux-saga/effects';

import ProductService from '@services/productService';

import {
  APP_STARTLOADING,
  APP_STOPLOADING,
  PRODUCTS_GET_REQUEST,
  PRODUCTS_GET_LOADED,
  PRODUCTS_GET_ERROR,
  PRODUCT_GET_REQUEST,
  PRODUCT_GET_LOADED,
  PRODUCT_GET_ERROR,
} from '../types';

function* getProductsSaga(payload) {
  try {
    //
    yield put({ type: PRODUCTS_GET_LOADED, payload: [{ prductName: 'test' }] });
  } catch (err) {
    const errors = err.payload || err;
    yield put({ type: PRODUCTS_GET_ERROR, payload: errors });
  }
}

/*
 * Startup flow to allow concurrent actions to be dispatched
 */
function* startupFlow() {
  while (true) {
    // watching for authentication actions
    const action = yield take([PRODUCTS_GET_REQUEST]);

    yield put({ type: APP_STARTLOADING });

    if (action.type === PRODUCTS_GET_REQUEST) {
      yield call(getProductsSaga, action.payload);
    }

    yield put({ type: APP_STOPLOADING });

    yield action;
  }
}

export default function* ProductSagas() {
  yield all([startupFlow()]);
}
