import React from 'react';
import classNames from 'classnames';

import styles from './Tabs.module.scss'

class Tabs extends React.Component {
  state = {
    activeTab: this.props.children[0]?.props.label
  }

  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, child => {
          buttons.push(child.props.label)
          if (child.props.label === this.state.activeTab)
            content = child.props.children
        })}

        <TabButtons
          activeTab={this.state.activeTab}
          buttons={buttons}
          changeTab={this.changeTab}
        />
        <div className={styles.tabContent}>{content}</div>
      </div>
    );
  }
}

const TabButtons = ({ buttons, changeTab, activeTab }) => {
  return (
    <div className={styles.tabButtonWrapper}>
      {buttons.map((button, index) => (
        <button
          key={index}
          className={classNames(styles.tabButton, button === activeTab ? styles.active : '')}
          onClick={() => changeTab(button)}
        >
          {button}
        </button>
      ))}
    </div>
  )
};

const Tab = props => {
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
};

Tabs.Tab = Tab;

export default Tabs;
