import { useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { reqMenuAction } from '@stores/menu/actions';
import { useMenuSelector } from '@stores/menu/selectors';

import styles from './Header.module.scss';

const Nav = dynamic(() => import('@components/Nav'), { ssr: false });

const Header = ({ className, headerClose, setHeaderValue }) => {
  const dispatch = useDispatch();
  const menuItems = useSelector(useMenuSelector);
  const router = useRouter();
  const { locale } = router;

  useEffect(() => {
    dispatch(reqMenuAction(menuItems));
  }, []);

  const headerMenu = menuItems[locale]?.filter((menuItem) => menuItem.id !== 1);

  return (
    <header className={classNames(styles.Header, className)}>
      <Nav
        menuItems={headerMenu}
        headerClose={headerClose}
        setHeaderValue={setHeaderValue}
      />
    </header>
  );
};

export default Header;
