import { isFeatureEnabled, getFeatureDisplayValue, checkEnabled } from '@components/OptionalFeature';

export function buildPostStructuredData(data) {
  return {
    '@type': 'Blog Post',
    'dateCreated': data?.DisplayPage?.CreateDate || '2019-02-11T11:11:11',
    'datePublished': data?.PostedDate || '2019-02-11T11:11:11',
    'dateModified': data?.LastModifiedBy || '2019-02-11T11:11:11',
    'inLanguage': data?.loc,
    'creator': {
      '@type': 'Person',
      'name': data?.DisplayPage?.CreateBy,
    },
    'publisher': {
      '@type': 'Organization',
      'name': data?.DisplayPage?.CreateBy,
    },
    'articleSection': data?.title,
    'articleBody': data?.description
  };
}
